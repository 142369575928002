import React from 'react'
import { motion } from 'framer-motion'

const SplitText = (props:any):JSX.Element => {
	const { children } = props
	const words = children.split('')
	return words.map((word:string, i:number) => (
		<div
			key={`sub-split-text-${children + i}`}
			style={{ display: 'inline-block', overflow: 'hidden' }}
		>
			<motion.div
				{...props}
				style={{
					display: 'inline-block', willChange: 'transform',
				}}
				custom={i}
			>
				{/* {word + (i !== words.length - 1 ? '\u00A0' : '')} */}
				{word}
			</motion.div>
		</div>
	))
}

export default SplitText
