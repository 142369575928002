import styled from 'styled-components'
// import Link from 'gatsby-link'
// import { device, desktopDemoDevice } from '../../../utils/device'

const LinkButton = styled.button`
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	height: 32px;
	width:100px;
	color: var(--gray3);
	background-color: transparent;
  font-weight: 700;
  line-height: 21px;
	text-align: right;
	box-sizing:border-box;
`

export const LogoutButton = styled(LinkButton)`
	color: var(--gray3);
	font-weight: 400 !important;
`

export const SignInButton = styled(LinkButton)`
	color: var(--brandColor);
`

export const AuthContainer = styled.div`
	width: 100%;
	height: 32px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content:space-between;
	margin-top:0px;
	padding: 0 20px 0px 8px;
`

export const ProfileContainer = styled.div`
	width: 100%;
	height: 32px;
	/* box-sizing: border-box; */
	/* border: 1px solid red; */
	display: flex;
	flex-direction:row;
	justify-content:flex-start;
	align-items:flex-start;
	/* justify-content: left; */
`

export const ProfileImageContainer = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-sizing: border-box;
	/* border: 1px solid red; */
	margin: 0px;
	padding: 0px;
	/* display:inline-flex; */
	/* position:relative; */
`

export const ProfileName = styled.div`
	width: 120px;
	height: 32px;
	box-sizing: border-box;
	margin-left: 0px;
	padding-top: 6px;

	font-size: 16px;
	color: var(--gray1);
	font-weight: 400;
	line-height: 21px;
	font-style: normal;
	/* border:1px solid red; */

	white-space: nowrap;
  overflow: hidden;
	text-overflow:ellipsis;

`
