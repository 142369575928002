import React, { useRef } from 'react'
import * as Styles from './styles'
import ModalOverlay from '../OverlayBackground'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

interface ComponentProps {
	onCustomize: Function
	onShareAsIs: Function
	onDismiss: Function
}

const CustomizeModal: React.FC<ComponentProps> = (props) => {
	const { onCustomize, onDismiss, onShareAsIs } = props

	const divRef = useRef<HTMLDivElement | null>(null)
	useOnClickOutside(divRef, () => onDismiss())

	return (
		<ModalOverlay
			isActive
			onClick={() => { onDismiss() }}
		>
			<Styles.Modal ref={divRef}>
				<Styles.Title>
					Make It Your Own!
				</Styles.Title>
				<Styles.SubTitle>
					Add a personal touch to your Stickie before sending it.
				</Styles.SubTitle>
				<Styles.OkButton onClick={() => {
					onCustomize()
				}}
				>
					Customize
				</Styles.OkButton>
				<Styles.NoThanksButton onClick={() => onShareAsIs()}>Share as is</Styles.NoThanksButton>
			</Styles.Modal>
		</ModalOverlay>
	)
}

export default CustomizeModal
