
import { useState } from 'react'

export interface HookOutput {
	executingAction: boolean
	setExecutingAction: any
}

export interface IAction {
	label: string
	id: string
}

const useStickieUI = (): HookOutput => {
	const [executingAction, setExecutingAction] = useState<boolean>(false)

	return {
		executingAction,
		setExecutingAction,
	}
}

export default useStickieUI
