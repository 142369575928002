import React from 'react'
import * as Styles from './styles'
import ActionList from './ActionList'
import { ITransitionButton, TTransitionButtonProperties, getTansitionButtonFormFieldType } from '../../../common/shared/npc-gen-1/sm-schema'
import CollectEmail from './CollectEmail'

interface ComponentProps {
	transitionButtons: TTransitionButtonProperties | undefined
	actionClickHandler: Function
	disabled: boolean
}

const Actions:React.FC<ComponentProps> = (props) => {
	const {
		actionClickHandler, disabled, transitionButtons,
	} = props

	const doAction = (action:ITransitionButton):void => {
		// const { event } = action
		actionClickHandler(action)
	}

	if (transitionButtons) {
		const formFieldType = getTansitionButtonFormFieldType(transitionButtons)
		if (formFieldType) {
			return (
				<Styles.Container>
					<CollectEmail
						onDismiss={() => {
							actionClickHandler({ event: 'cancel', label: 'Cancel' })
						}}
						onSuccess={() => {
							actionClickHandler({ event: formFieldType.event, label: 'Email' })
						}}
					/>
				</Styles.Container>
			)
		}
		return (
			<Styles.Container>
				<ActionList actions={transitionButtons} doAction={doAction} disabled={disabled} />
			</Styles.Container>
		)
	}
	return <></>
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.disabled === nextProps.disabled && prevProps.transitionButtons === nextProps.transitionButtons)
export default React.memo(Actions, areEqual)
