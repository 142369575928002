import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

export const Container = styled.div`
  /* position: absolute; */
	
	/* background-color: var(--white1); */

	position: fixed;
	bottom: 8px;
	width: 100%;
	z-index: 290000;



  /* width: 100vw; */
  height: 56px;
  display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	

	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
	}
`

export const ActionsContainer = styled.div`

  display: flex;


	justify-content: start;
  align-items: center;

  /* gap: 2px; */
	overflow-y: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  /* box-shadow: 0 3px 10px 0 rgba(#000, 0.1); */
  -webkit-overflow-scrolling: touch;
  /* scroll-padding: 0px 5px 0px 5px; */
  /* padding: 0px 5px 0px 5px; */
  /* margin: 3rem auto 0; */
  
	
	::-webkit-scrollbar {
		display: none;
	}



	max-width: 100vw;
	height: 56px;
	/* width: 100vw; */


	border:1px solid red;

	@media ${device.isTabletOrLarger} { 
		max-width: ${desktopDemoDevice.width}px;
	}
`

export const ActionsButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 150px;
  /* width: 30px; */
  height: 64px;
  scroll-snap-align: center;
  scroll-snap-stop: normal;
	/* border:1px solid green; */
	margin: 0px 10px 0px 10px;
`

// .message-bubble-container.us {
//   justify-content: flex-end;
// }

export const ActionButton = styled.button<{disabled:boolean}>`
	text-align: center;
	background-color: var(--brandColor);
  margin: 2px 2px 2px 2px;
	padding: 6px 18px 6px 18px;
  border-radius: 18px;
  /* border: 0.25px solid var(--gray5); */
  height: 48px;
  min-width: 150px;
  color: var(--white1);
	font-family: var(--slideshowFontFamily);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.26px;
	line-height: 21px;
	white-space: nowrap;
	${(props) => props.disabled && css`
     opacity 0.2;
  `}
`

export const NextButton = styled.button<{disabled:boolean}>`
	background-color: transparent;
  margin: 1em 1em 1em 1em;
	border: none;
  height: 56px;
  width: 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  ${(props) => props.disabled && css`
     opacity 0.2;
  `}
`

export const NextArrow = styled.img`
 object-fit: contain;
 width: 48px;
 height: 48px;
`

export const SignUpForm = styled.div`
	width: 100%;
	display:flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 10px;
`
/*
export const CancelButton = styled.button`
	cursor: pointer;
	height: 36px;
	width: 100px;
 	padding:0px 10px 0px 10px;
	display: flex;
	font-size: 16px;

	-webkit-align-items: center;
	align-items: center;

	-webkit-justify-content: center;
	justify-content: center;

	box-sizing: border-box;

	background-color: transparent;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	color: var(--gray1);
`
*/

export const RightSide = styled.div`
	height: 36px;
	width: 36px;
 	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
`

export const SpinnerImage = styled.img`
 object-fit: contain;
 width: 24px;
 height: 24px;
`
