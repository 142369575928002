import styled, { createGlobalStyle } from 'styled-components'
import { motion, Variants } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'
// import { EffectPositioningContainerProps, EffectResizingContainerProps } from '../Effects/common'

interface StickerBackgroundProps {
	url?:string
	color?:string
}

export const customizationModeVariants:Variants = {
	customize: {
		scale: 0.7,
		top: '56px',
	},
	normal: {
		scale: 1,
		top: '0px',
	},
}

export const GlobalStyle = createGlobalStyle`
	body {
		min-height: 100vh;
		/* mobile viewport bug fix */
		min-height: -webkit-fill-available;
		/* border: 1px solid yellow; */
		font-family: var(--slideshowFontFamily);

		/* Should prevent touch enabled elastic scrolling */
		/* there are caveats, that is disables ALL scrolling in the body, so may need to fix for carousel */
		/* https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/ */
		position: fixed;
		
		overflow: hidden;
		/* end of scroll fix */
	}

	div {
		font-family: var(--slideshowFontFamily);
	}
	
	button {
		font-family: var(--slideshowFontFamily);
	}

	html {
		height: -webkit-fill-available;
	}
	`

const PreloadFontPhatomDiv = styled.div`
	font-family: var(--slideshowFontFamily);
  position: absolute;
  top: -100px;
  left: -100px;
  width: auto;
  height: auto;
  border: 1px solid;
`

export const PreloadFontPhatomDiv300 = styled(PreloadFontPhatomDiv)`
  font-weight: 300;
`

export const PreloadFontPhatomDiv400 = styled(PreloadFontPhatomDiv300)`
  font-weight: 400;
`
export const PreloadFontPhatomDiv500 = styled(PreloadFontPhatomDiv)`
  font-weight: 500;
`
export const PreloadFontPhatomDiv700 = styled(PreloadFontPhatomDiv)`
  font-weight: 700;
`
// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/

export const StyledSiteContent = styled(motion.div)
	.attrs(() => ({
		initial: 'normal',
		variants: customizationModeVariants,
		isCustomizing: false,
	}))<{isCustomizing:boolean}>`
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 200000;
	background-color: #FFFFFF;

	-webkit-tap-highlight-color:rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;
	::-webkit-scrollbar { 
    display: none;  /* Chrome Safari */
	}

	transform-origin: top center;
	box-shadow: ${(props) => `${props.isCustomizing ? '0px 4px 4px rgba(0, 0, 0, 0.06)' : 'none'}`};

	width: 100%; 
	height: 100vh;



display:flex;
flex-direction: column;
justify-content: space-between;

/* border:1px solid yellowgreen; */

	/* For Desktop Optimization */
	@media ${device.isTabletOrLarger} { 
		/* height: calc(${desktopDemoDevice.height}px - 90px); */
		
		height: calc(${desktopDemoDevice.height}px);

	}


	/* background-color: #cccccc; */
`

export const ContentRegion = styled.div`
	/* position: relative;
	top: 0px;
	left: 0px; */
	width: 100%;
	height: 100vw;
	@media ${device.isTabletOrLarger} { 
		/* width: ${desktopDemoDevice.width}px; */
		height: ${desktopDemoDevice.height}px;
	}

	/* border:2px solid purple; */
`

export const LowerSection = styled.div`
	z-index: 1000000;
	width: 100%;
	height: 140px;
	/* border:1px solid red; */
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
	}

	/* background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255, 1)); */

	/* border:2px solid purple; */
`

export const DrawingCanvas = styled.div`
	position: relative;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vw;
	@media ${device.isTabletOrLarger} { 
		height: ${desktopDemoDevice.width}px;
		/* width: ${desktopDemoDevice.width}px; */
	}
	overflow: hidden;
`
interface EventMessageProps {
  textboxOffset: string
}
export const StyledEventMessage = styled.div<EventMessageProps>`
	position: relative;
	top: 0px;
	/* top: ${(props) => (props.textboxOffset ? props.textboxOffset : '0px')}; */
	margin-top: ${(props) => (props.textboxOffset ? props.textboxOffset : '0px')};
	margin-bottom: 0px;
	left: 0px;
	width: 100%;
	z-index: 2000;
	display: flex;
  flex-direction: column;
  justify-content: center;
	align-items: center;
	letter-spacing: 0px;
	font-weight: var(--paragraphfontWeight);
	font-size: 16px;
	line-height: 21px;
	color: var(--gray1);
`
export const MoveContent = styled.div`
	width: 100%;
	height: 56px;
	border: 3px dotted green;
`

export const StickerBackgroundContainer = styled.div<StickerBackgroundProps>`
	position:absolute;
	z-index: 0;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 100vw;
	height: 100vh;
	background-image: ${(props) => (props.url ? `url(${props.url})` : 'none')};
	background-color: ${(props) => (props.color ? `${props.color}` : 'transparent')};
	background-size: cover;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.height}px;
	}
`

export const StickieName = styled.div`
	position:fixed;
	z-index: 2200000;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 56px;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
	}
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-weight: var(--paragraphfontWeight);
	font-size: 16px;
	line-height: 21px;
	color: var(--gray1);
	
`
