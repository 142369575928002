import { useEffect, useState } from 'react'
import { find, isNil } from 'lodash'
import { SlideTypeKeyed, EffectTypeKeyed } from '../../../common/shared/npc-gen-1/schema'

const getOverrideUrl = (currentSlide:SlideTypeKeyed): string | null => {
	const overrideEffect = find(currentSlide.effects, (effect: EffectTypeKeyed) => effect.type === 'sticker' && !isNil(effect.url))
	return (overrideEffect && overrideEffect.url) || null
}

const getOverrideEyesUrl = (currentSlide:SlideTypeKeyed): string | null => {
	const overrideEffect = find(currentSlide.effects, (effect: EffectTypeKeyed) => effect.type === 'eyes' && !isNil(effect.url))
	return (overrideEffect && overrideEffect.url) || null
}

const useStickerImage = (currentSlide: SlideTypeKeyed, defaultAvatarUrl:string, defaultEyesUrl:string):any => {
	const [stickerImageUrl, setStickerImageUrl] = useState<string>(():string => getOverrideUrl(currentSlide) || defaultAvatarUrl)
	const [eyesUrl, setEyesImageUrl] = useState<string>(():string => getOverrideEyesUrl(currentSlide) || defaultEyesUrl)
	useEffect(() => {
		const newUrl = getOverrideUrl(currentSlide)
		const newEyesUrl = getOverrideEyesUrl(currentSlide)

		if (!isNil(newUrl)) {
			setStickerImageUrl(newUrl)
		}

		if (!isNil(newEyesUrl)) {
			setEyesImageUrl(newEyesUrl)
		}
	}, [currentSlide])
	return [stickerImageUrl, eyesUrl]
}

export default useStickerImage
