import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState } from 'react'
import uniqId from 'uniqid'
import { IEnvironmentStateDefinition } from '../../../common/shared/npc-gen-1/sm-schema'

export interface ITickEnvironmentState {
	state: IEnvironmentStateDefinition | undefined
	stateId: string
}

export interface HookOutput {
	environmentState: ITickEnvironmentState
}

const useEnvironmentState = (collection: string): HookOutput => {
	const [environmentState, setEnvironmentState] = useState<ITickEnvironmentState>({ state: undefined, stateId: uniqId() })

	useEffect(() => {
		const ref = firebase.firestore()
			.collection('nftCollections')
			.doc(collection)

		const unsubscribe = ref.onSnapshot((doc:any) => {
			const state = doc.data() as IEnvironmentStateDefinition | undefined
			setEnvironmentState({
				state,
				stateId: uniqId(),
			})

			return (() => {
				unsubscribe()
			})
		})
	}, [collection])

	return {
		environmentState,
	}
}

export default useEnvironmentState
