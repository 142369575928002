"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllEyes = exports.getAllImages = exports.isValidDraftSlideshow = exports.isValidPublishedSlideshow = exports.getKeyedSlideshowSchema = void 0;
const lodash_1 = require("lodash");
/*
export const toKeyValuePair = (items:Array<ICustomizedMessage>):any => {
    let obj = {}
    items.forEach((item:ICustomizedMessage) => {
        obj = {
            ...obj,
            [item.id]: item.message,
        }
    })
    return obj
}
*/
const getKeyedSlideshowSchema = (slideshow) => {
    let c = 0;
    const slides = slideshow.slides.map((slide) => {
        const effects = slide.effects.map((effect) => {
            c += 1;
            return {
                ...effect,
                id: effect.id || `${c}`,
            };
        });
        return {
            ...slide,
            effects,
            id: slide.id,
        };
    });
    return {
        ...slideshow,
        slides,
    };
};
exports.getKeyedSlideshowSchema = getKeyedSlideshowSchema;
const isValidPublishedSlideshow = (slideshow) => {
    let valid = true;
    const { slides, 
    // url,
    templateEngine,
    // social,
     } = slideshow;
    valid = valid && !lodash_1.isNil(slides);
    // valid = valid && !isNil(url)
    valid = valid && !lodash_1.isNil(templateEngine);
    // valid = valid && !isNil(social)
    return valid;
};
exports.isValidPublishedSlideshow = isValidPublishedSlideshow;
const isValidDraftSlideshow = (slideshow) => {
    let valid = true;
    const { slides, templateEngine, social, draftShareUrl, } = slideshow;
    valid = valid && !lodash_1.isNil(slides);
    valid = valid && !lodash_1.isNil(draftShareUrl);
    valid = valid && !lodash_1.isNil(templateEngine);
    valid = valid && !lodash_1.isNil(social);
    return valid;
};
exports.isValidDraftSlideshow = isValidDraftSlideshow;
/*
export const getEditableMessageTexts = (slideshow: SlideshowTypeKeyed): Array<IMessageTextsKeyed> => {
    const x = flatten(slideshow.slides.map((slide: SlideTypeKeyed) => {
        let allMessageTexts:Array<IMessageTextsKeyed> = new Array<IMessageTextsKeyed>()

        if (slide.messageTexts) {
            allMessageTexts = [...allMessageTexts, ...slide.messageTexts.filter((item:IMessageTextsKeyed) => item.isEditable)]
        }
        return allMessageTexts
    }))

    if (slideshow.customize && slideshow.customize.length > 0) {
        x.push({
            id: 'name',
            isEditable: true,
            message: '',
        } as IMessageTextsKeyed)
    }

    return x
}
*/
const getAllImages = (slideshow) => {
    const effectImages = new Array();
    lodash_1.flatten(slideshow.slides.map((slide) => slide.effects))
        .filter((effectObj) => effectObj.type === 'image' || effectObj.type === 'gif1x' || effectObj.type === 'sticker' || effectObj.type === 'eyes')
        .forEach((effectObj) => {
        if (effectObj.url) {
            effectImages.push(effectObj.url);
        }
        if (effectObj.urls) {
            effectImages.push(...effectObj.urls);
        }
    });
    const all = lodash_1.compact([
        (slideshow.sticker.background ? slideshow.sticker.background.cdnUrl : null),
        slideshow.sticker.cdnUrl || null,
        ...effectImages,
    ]);
    return all;
};
exports.getAllImages = getAllImages;
const getAllEyes = (slideshow) => {
    const effectImages = new Array();
    lodash_1.flatten(slideshow.slides.map((slide) => slide.effects))
        .filter((effectObj) => effectObj.type === 'eyes')
        .forEach((effectObj) => {
        if (effectObj.url) {
            effectImages.push(effectObj.url);
        }
        if (effectObj.urls) {
            effectImages.push(...effectObj.urls);
        }
    });
    const all = lodash_1.compact([
        ...effectImages,
    ]);
    return lodash_1.sortedUniq(all);
};
exports.getAllEyes = getAllEyes;
