// /* eslint-disable react/require-default-props */
import { isNil, isEmpty, isEqual } from 'lodash'
import React, { useEffect, useContext } from 'react'
import * as Styles from './styles'
import ShareButtons from '../../share/share.buttons'
import CustomizedModal from '../CustomizeModal'
import { MixpanelContext, AppUIEvent } from '../../../common/tracking'

import {
	SlideshowContext, SlideshowContextProps,
} from '../SlideshowProvider'
import {
	SlideshowTypeKeyed, getEditableMessageTexts, IMessageTextsKeyed, ICustomizedMessage,
} from '../../../common/shared/schema12'
import { getSocialProperties } from '../../../common/social'
import GetNotifiedModal from '../GetNotifiedModal'

import { IShareInfo, NAME_ITEM } from '../../../hooks/useCustomShare'
import { ShareStageType } from '../../../hooks/useShareUIManager'

type ComponentProps = {
	// currentSlide: string
	// slideShowMode: SlideshowModeType
	setSlideshowMode: any
	setPreviewRecipientName: any
	// setPreviewCustomMessages: any
	handleRestart: Function
	setOpen: Function
	shareInfo: IShareInfo
	// setCustomizationMode: any
	setCustomizedMessages: (a:Array<ICustomizedMessage>)=>void
	customizedMessages: Array<ICustomizedMessage>
	// setShareCount: (nextShareCount:number)=>void
	// shareCount: number
	shareStage: ShareStageType
	setShareStage: any
	hasSeenModal: boolean
	setHasSeenModal: any
}

// const MobileShare = React.forwardRef((props: ComponentProps, ref:any): JSX.Element => {
const MobileShare:React.FC<ComponentProps> = (props) => {
	const { slideshow }:Partial<SlideshowContextProps> = React.useContext(SlideshowContext)
	const {
		ogTitle, shareText = '', sticker,
	} = slideshow as SlideshowTypeKeyed

	const {
		setSlideshowMode, setPreviewRecipientName, /* setPreviewCustomMessages, */ handleRestart, setOpen,
		shareInfo, shareStage, setShareStage, /* setCustomizedMessages: setCustomizedMessagesState, */ setCustomizedMessages, customizedMessages, hasSeenModal, setHasSeenModal,
	} = props

	const { url: shortUrl, id: customId } = shareInfo
	const [editableMessages] = React.useState<Array<IMessageTextsKeyed>>(():Array<IMessageTextsKeyed> => (slideshow ? getEditableMessageTexts(slideshow) : []))
	const [isCustomizable] = React.useState<boolean>(():boolean => editableMessages.length > 0)
	const [showDesktopShareDialog, setShowDesktopShareDialog] = React.useState(false)
	const socialProperties = getSocialProperties(ogTitle)
	const text = shareText || '👀 at this...'
	const twitterHandle = 'stickiesfun'
	const mixpanel = useContext(MixpanelContext)

	function stopPropagation(e:any):void {
		e.stopPropagation()
	}

	const hasCustomizableTextFields = editableMessages.filter((item:IMessageTextsKeyed) => item.id !== NAME_ITEM).length > 0

	const handleMessageChange = (event:any):void => {
		const newVal = event.target.value as string
		const { id: messageId }:{id:string} = event.currentTarget.dataset
		const item = editableMessages.find((message:IMessageTextsKeyed) => message.id === messageId)
		if (item) {
			if (item.message !== newVal && !isEmpty(newVal)) {
				const existingCustomizedMessage = customizedMessages.find((message:ICustomizedMessage) => message.id === messageId)
				if (existingCustomizedMessage) {
					// Overwriting Exitsing Message
					const idx = customizedMessages.findIndex((message:ICustomizedMessage) => message.id === messageId)
					const copyOfMessages = [...customizedMessages]
					existingCustomizedMessage.message = newVal
					copyOfMessages[idx] = existingCustomizedMessage
					setCustomizedMessages(copyOfMessages)
				} else {
					// Insert New Message
					setCustomizedMessages([...customizedMessages, {
						id: messageId,
						message: newVal,
					} as ICustomizedMessage])
				}
			} else {
				const idx = customizedMessages.findIndex((message:ICustomizedMessage) => message.id === messageId)
				if (idx > -1) {
					// Delete Existing Message
					const copyOfMessages = [...customizedMessages]
					copyOfMessages.splice(idx, 1)
					setCustomizedMessages(copyOfMessages)
				}
			}
		}
	}

	useEffect(() => {
		setOpen(shareStage !== 'inactive')

		if (shareStage === 'inactive' && showDesktopShareDialog) {
			setShowDesktopShareDialog(false)
		}

		if (shareStage === 'share options') {
			if (isEmpty(shortUrl)) {
				console.error('no short url available')
			} else if (navigator.share) {
				navigator
					.share({
						title: socialProperties.ogTitle,
						text,
						url: shortUrl ?? '',
					})
					.then(() => {
						// setShareStage('get-notified')
					})
					.catch((err) => {
						console.error(err)
						// setShareStage(isCustomizable ? 'customize' : 'inactive')
					})
					.finally(() => {
						setSlideshowMode('presentation')
						setShareStage('get-notified')
					})
			} else {
				setShowDesktopShareDialog(true)
			}
		}
	}, [shareStage, setOpen, showDesktopShareDialog, isCustomizable, shortUrl, socialProperties.ogTitle, text, setShareStage, setSlideshowMode])

	/*
	const handleOnClick = () :void => {
		if (mixpanel) {
			mixpanel.track(
				'Sticker: Share Tapped',
				{
					id,
					// customId: newCustomId,
					stickerIsCustomizable: isCustomizable,
					slideId: currentSlide,
					slug,
					shareTitle: socialProperties.ogTitle,
					shareText: text,
					// shareUrl: newShortUrl,
					shareWidgetIsAvailable: !isNil(navigator.share),
				},
			)
		}

		setShareCount(shareCount + 1)
		setShareStage(isCustomizable ? 'customize' : 'share options')
	}
	*/

	const doAnimation = (isCustomize: boolean, isPreview: boolean, ifIsCustomize:string, ifIsPreview:string, defaultOutput:string):string => {
		if (isCustomize) {
			return ifIsCustomize
		}

		if (isPreview) {
			return ifIsPreview
		}

		return defaultOutput
	}

	const customNameField = ():any => {
		const isPreview = shareStage === 'preview'
		const isCustomize = shareStage === 'customize'
		const isShareOptions = shareStage === 'share options'
		const recipientName = customizedMessages.find((message:ICustomizedMessage) => message.id === NAME_ITEM)?.message

		return (
			<>
				<Styles.StyledShareOverlay
					isActive={isCustomize}
					onClick={() => {
						setPreviewRecipientName(undefined)
						setSlideshowMode('presentation')
						setShareStage('inactive')
					}}
				/>
				<Styles.CustomFieldContainer animate={doAnimation(isCustomize, isPreview, 'visible', 'preview', 'hidden')} isPreview={isPreview}>

					{ isCustomize && (
						<Styles.TextButton onClick={() => {
							mixpanel.track(AppUIEvent.navigationCancelShare)
							setPreviewRecipientName(undefined)
							setSlideshowMode('presentation')
							setShareStage('inactive')
							setHasSeenModal(false)
						}}
						>
							Cancel
						</Styles.TextButton>
					)}

					{ shareStage === 'preview' && (
						<Styles.EditButton
							onClick={() => {
								setShareStage('customize')
							}}
							darken={false}
						>
							Edit
						</Styles.EditButton>

					)}

					<Styles.InputContainer>
						<Styles.NameBox
							type="text"
							placeholder="Friend's Name"
							disabled={isPreview || isShareOptions}
							data-id={NAME_ITEM}
							onChange={handleMessageChange}
							maxLength={18}
						/>

						{ shareStage === 'preview' && (

							<Styles.PreviewLabel>
								Preview
							</Styles.PreviewLabel>

						)}
					</Styles.InputContainer>

					{ shareStage === 'customize' && (
						<Styles.TextButton
							onClick={() => {
								mixpanel.track(AppUIEvent.navigationPreview)
								setPreviewRecipientName(recipientName)
								setSlideshowMode('preview')
								setShareStage('preview')
								handleRestart()
							}}
							// disabled={!recipientName}
						>
							Preview
						</Styles.TextButton>
					)}

					{ shareStage === 'preview' && (
						<Styles.SendButton
							disabled={isNil(shortUrl)}
							onClick={() => {
								mixpanel.track(AppUIEvent.navigationSend)
								setShareStage('share options')
							}}
							darken={false}
						>
							Send
						</Styles.SendButton>

					)}

				</Styles.CustomFieldContainer>
			</>
		)
	}

	const customMessageTexts = ():any => {
		const isPreview = shareStage === 'preview'
		const isCustomize = shareStage === 'customize'

		return (
			<>
				{/* <Styles.StyledShareOverlay
					isActive={isCustomize}
					onClick={() => {
						setPreviewCustomMessages(undefined)
						setSlideshowMode('presentation')
						setShareStage('inactive')
					}}
				/> */}
				<Styles.CustomMessagesContainer animate={doAnimation(isCustomize, isPreview, 'visible', 'preview', 'hidden')} isPreview={isPreview}>
					{ isCustomize && (
						<Styles.TextButton onClick={() => {
							mixpanel.track(AppUIEvent.navigationCancelShare)
							setPreviewRecipientName(undefined)
							setSlideshowMode('presentation')
							setShareStage('inactive')
							setHasSeenModal(false)
						}}
						>
							Cancel
						</Styles.TextButton>
					)}

					{ shareStage === 'preview' && (
						<Styles.EditButton
							onClick={() => {
								setShareStage('customize')
							}}
							darken={false}
						>
							Edit
						</Styles.EditButton>

					)}

					<Styles.InputContainer>

						{ shareStage === 'preview' && (

							<Styles.PreviewLabel>
								Preview
							</Styles.PreviewLabel>

						)}
					</Styles.InputContainer>

					{ shareStage === 'customize' && (
						<Styles.TextButton
							onClick={() => {
								mixpanel.track(AppUIEvent.navigationPreview)
								// setPreviewCustomMessages(customizedMessages)
								setSlideshowMode('preview')
								setShareStage('preview')
								handleRestart()
							}}
						>
							Preview
						</Styles.TextButton>
					)}

					{ shareStage === 'preview' && (
						<Styles.SendButton
							disabled={isNil(shortUrl)}
							onClick={() => {
								mixpanel.track(AppUIEvent.navigationSend)
								setShareStage('share options')
							}}
							darken={false}
						>
							Send
						</Styles.SendButton>

					)}
				</Styles.CustomMessagesContainer>
			</>
		)
	}

	return (
		<>
			{
				// HACK FOR NOW.  FIX ME
				// Needs better logic to support customized names and customized messages
				(shareStage === 'customize' || shareStage === 'preview') && editableMessages.find((message:IMessageTextsKeyed) => message.id === NAME_ITEM) && (
					customNameField()
				)
			}
			{
				(shareStage === 'customize' || shareStage === 'preview') && hasCustomizableTextFields && (
					customMessageTexts()
				)
			}

			{
				!hasSeenModal && shareStage === 'prompt-to-customize' && (
					<CustomizedModal
						onDismiss={() => {
							setShareStage('inactive')
						}}
						onCustomize={() => {
							// setHasSeenModal(true)
							setShareStage('customize')
							handleRestart()
						}}
						onShareAsIs={() => {
							// setHasSeenModal(true)
							setShareStage('share options')
						}}

					/>
				)
			}

			{/* <Styles.ShareButtonContainer>
				<Styles.Wrapper ref={ref} onClick={handleOnClick} {...props} />
			</Styles.ShareButtonContainer> */}

			{
				(shareStage === 'share options') && showDesktopShareDialog && (

					<Styles.StyledShareOverlay
						isActive
						onClick={() => {
							setPreviewRecipientName(undefined)
							// setPreviewCustomMessages(undefined)
							setSlideshowMode('presentation')
							setShareStage('get-notified')
						}}
					>
						<Styles.StyledShareDialog onClick={() => stopPropagation}>
							<ShareButtons
								title={socialProperties.ogTitle}
								url={shortUrl as string}
								twitterHandle={twitterHandle}
								twitterHashTags={['stickiesfun']}
								fbHashtag="stickiesfun"
								stickerImageUrl={sticker.cdnStickerWithBkgUrl || sticker.cdnUrl || sticker.url}
							/>
						</Styles.StyledShareDialog>
					</Styles.StyledShareOverlay>
				)
			}

			{
				(shareStage === 'get-notified') && (
					<GetNotifiedModal initialOpenState onDismiss={() => { setShareStage('inactive') }} customId={customId || ''} />
				)
			}
		</>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => ((prevProps.shareInfo === nextProps.shareInfo)
		&& (isEqual(prevProps.shareStage, nextProps.shareStage))
		&& (prevProps.hasSeenModal === nextProps.hasSeenModal)
		&& (prevProps.customizedMessages === nextProps.customizedMessages)
)

export default React.memo(MobileShare, areEqual)
