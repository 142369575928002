import styled from 'styled-components'

export const LargeButton = styled.button`
	cursor: pointer;
	font-size: 16px;
	padding: 0px;
	height: 48px;
	width: 327px;
	color: var(--white1);
	background-color: var(--brandColor);
  font-family: 'DM Sans';
  font-weight: 700;
  letter-spacing: -0.26px;
  line-height: 19px;
	text-align: center;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	box-sizing:border-box;
	border-radius: 8px;
`
