import React, { useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
// import Menu from './menu'
import Menu from './menuWithStoredValue'
import * as Styles from './styles'
import ModalOverlay from '../OverlayBackground'

interface ComponentProps {
  // soundIsPlaying:boolean
	// url: string
	// logoUrl: string
	title: string
	views: number
	creatorIdOrHandle:string
	onShareRequest:Function
	onReplayRequest:Function
	onNavigateAway:Function
	onIOSContextMenu: Function
	open: boolean
	setOpen: Function
	shareEnabled: boolean
	onBidHandler:Function
	itemValue: number
}

const MenuContainer = (props:ComponentProps):JSX.Element => {
	const {
		onReplayRequest, open, setOpen, onBidHandler,
	} = props
	// const [open, setOpen] = useState<boolean>(isOpen)
	// const node = useRef()
	// const menuId = 'main-menu'
	const divRef = useRef<HTMLDivElement | null>(null)
	useOnClickOutside(divRef, () => setOpen(false))

	const onReplayRequestWithClose = React.useCallback(
		():void => {
			setOpen(false)
			onReplayRequest()
		},
		[onReplayRequest, setOpen],
	)

	const onBidRequestWithClose = React.useCallback(
		():void => {
			setOpen(false)
			onBidHandler()
		},
		[onBidHandler, setOpen],
	)

	return (
		<>
			<Styles.MenuContainer ref={divRef}>
				<FocusLock disabled={!open}>
					<Menu {...props} onReplayRequest={onReplayRequestWithClose} onBidHandler={onBidRequestWithClose} />
				</FocusLock>
			</Styles.MenuContainer>
			<ModalOverlay isActive={open} />
		</>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.open === nextProps.open && prevProps.shareEnabled === nextProps.shareEnabled
	&& prevProps.itemValue === nextProps.itemValue)

export default React.memo(MenuContainer, areEqual)
