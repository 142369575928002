import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

interface ScoreBoardCSSProps {
  isActive: boolean
}

export const Container = styled(motion.div)`
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 15px;
	position: relative;
	z-index: 999999;
	left: 0px;
	height: 320px;
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	@media ${device.isTabletOrLarger} { 
		width:${desktopDemoDevice.width}px;
	} 
`

export const BottomTab = styled(motion.div)`
	cursor: pointer;
	background: inherit;
	box-shadow:inherit;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	position: absolute;
	bottom: -40px;
	left: calc((100vw / 2)  - (140px/2));
	height:40px;
	width: 140px;
	min-width: 140px;
	align-content: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	@media ${device.isTabletOrLarger} { 
		left:${desktopDemoDevice.width / 2 - (140 / 2)}px;
	}
`

export const BottomTabInnerContainer = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
`

export const ButtonContainer = styled.div`
	padding-top: 8px;
`

export const ScoreLabel = styled.div`
	height: 15px;
	width: 100%;
	color: var(--gray3);
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
`

export const ScoreValue = styled.div`
	height: 15px;
	width: 100%;
	color: var(--gray1);
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
`

export const LeaderboardContainer = styled.div`
	position:relative;
	height: 100%;
	width: 100%;
`

export const StartRanking = styled.div`
	text-align: center;
	box-sizing: border-box;
	padding-top: 40%;
	padding-left:40px;
	padding-right:40px;
	width: 100%;
	font-family: 'DM Sans';
	font-weight: 700;
	color: var(--gray1);
	font-size: 18px;
	line-height: 21px;
`

export const Overlay = styled.div<ScoreBoardCSSProps>`
	display: ${(props) => (props.isActive ? 'block' : 'none')};
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: 999998;
  background-color: rgba(0,0,0,.75);
	@media ${device.isTabletOrLarger} { 
		top: calc((100vh / 2) -  (${desktopDemoDevice.height + (desktopDemoDevice.borderWidth * 2)}/2));
		left: calc((100vw / 2) -  (${desktopDemoDevice.width + (desktopDemoDevice.borderWidth * 2)}/2));
		width: ${desktopDemoDevice.width + desktopDemoDevice.borderWidth * 2}px;
		height: ${desktopDemoDevice.height + desktopDemoDevice.borderWidth * 2}px;
		border-radius: ${desktopDemoDevice.borderRadius + desktopDemoDevice.borderWidth}px;
	}
`

export const CornerGrabber = styled.div`
	position: absolute;
	right:2px;
	bottom: 2px;
	width: 16px;
	height: 16px;
`
