import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

/*
export const Container = styled.div`
  position: fixed;
	bottom: 0px;
	width: 100%;
	z-index: 290000;
	display: flex;
  flex-direction: row;
  justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 24px;
	padding: 0px 14px 0px 14px;
`
*/

export const Container = styled(motion.div)`
  position: fixed;
	top: calc(100vw + 2px);
	left: 50%;
	width: 120px;
	z-index: 290000;
	display: flex;
  flex-direction: row;
  justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 18px;
		@media ${device.isTabletOrLarger} { 
		top: ${desktopDemoDevice.width + 2}px;
	} 
	transform: translateX(-50%);
	/* padding: 0px 14px 0px 14px; */
	/* border:1px solid red; */
`

export const Message = styled.div`
	display: flex;
  flex-direction: row;
  justify-content: center;
	align-items: center;
	min-width:120px;
	height: 18px;
	/* text-transform: uppercase; */
	font-size: 10px;
	color: var(--white1);
	border:1px solid var(--brandColor70Percent);
	
	
	box-sizing: border-box;
	padding: 2px 6px 2px 6px;
	border-radius: 12px;
	background-color: rgba(249, 0, 142, 0.08);
	color: var(--gray3);
	font-weight: 400;
	box-sizing: border-box;
`
