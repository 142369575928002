
import React from 'react'
import styled from 'styled-components'
import { desktopDemoDevice, device } from '../../../utils/device'

// TODO: make this overlay faein and out for nicer presentation
export const StyledShareOverlay = styled.div<{isActive:boolean}>`
	display: ${(props) => (props.isActive ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: 1000000;
  
  background-color: rgba(0,0,0,.90);

	@media ${device.isTabletOrLarger} { 
		top: calc((100vh / 2) -  (${desktopDemoDevice.height + (desktopDemoDevice.borderWidth * 2)}/2));
		left: calc((100vw / 2) -  (${desktopDemoDevice.width + (desktopDemoDevice.borderWidth * 2)}/2));
		width: ${desktopDemoDevice.width + desktopDemoDevice.borderWidth * 2}px;
		height: ${desktopDemoDevice.height + desktopDemoDevice.borderWidth * 2}px;
		border-radius: ${desktopDemoDevice.borderRadius - desktopDemoDevice.borderWidth}px;
	} 



`

const OverlayBackground = React.memo((props:any):JSX.Element => {
	const { children, isActive } = props
	return (
		<StyledShareOverlay isActive={isActive}>
			{children}
		</StyledShareOverlay>
	)
})

export default OverlayBackground
