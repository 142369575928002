import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import { ITransitionButton, TTransitionButtonProperties } from '../../../../common/shared/npc-gen-1/sm-schema'
import ActionButton from '../ActionButton'
import * as Styles from '../styles'

interface Props {
  actions: TTransitionButtonProperties
	doAction: (action: ITransitionButton) => void
	disabled: boolean
}

const ActionList:React.FC<Props> = (props) => {
	const { actions, doAction, disabled } = props

	return (
		<Styles.ActionsContainer>
			<AnimatePresence initial={false}>
				{actions
					.filter((action: ITransitionButton) => action.event !== 'tap')
					.map((action: ITransitionButton) => (
						<Styles.ActionsButtonContainer
							key={action.event}
							positionTransition
							initial={{ opacity: 0, x: -100 }}
							animate={{ opacity: 1, x: 0 }}
							exit={{ opacity: 0, transition: { duration: 0.15 } }}
						>
							<ActionButton
								key={action.event}
								action={action}
								doAction={doAction}
								disabled={disabled}
							/>
						</Styles.ActionsButtonContainer>
					))}
			</AnimatePresence>
		</Styles.ActionsContainer>
	)
}
export default ActionList
