
import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import * as Styles from './styles'
import PlaceholderImage from '../../../../images/svg/white_tongue.svg'
import {
	AppUIEvent, Locations, MixpanelContext,
} from '../../../../common/tracking'

interface AuthBoxProps {
	name:string | undefined | null
	// name: string
	profileUrl: string | undefined | null
	onLogout: Function
	isVerified: boolean
}

const Profile = React.memo(({ name, profileUrl }:{name:string;profileUrl:string|undefined|null}):JSX.Element => (
	<Styles.ProfileContainer>
		<Styles.ProfileImageContainer>
			{
				profileUrl ? (<img src={profileUrl} alt="Avatar" />) : <PlaceholderImage />
			}
		</Styles.ProfileImageContainer>
		<Styles.ProfileName>{name}</Styles.ProfileName>
	</Styles.ProfileContainer>
))

const AuthBox = React.memo((props:AuthBoxProps):JSX.Element => {
	const {
		isVerified, onLogout, name = 'Guest User', profileUrl,
	} = props

	const mixpanel = useContext(MixpanelContext)

	return (
		<Styles.AuthContainer>
			<Profile name={name as string} profileUrl={profileUrl} />
			{ isVerified && (
				<Styles.LogoutButton onClick={() => {
					mixpanel.track(AppUIEvent.logout, {
						location: Locations.stickieDrawer,
					})

					onLogout()
				}}
				>
					Log Out
				</Styles.LogoutButton>
			)}
			{ !isVerified && (
				<Styles.SignInButton onClick={() => {
					mixpanel.track(AppUIEvent.navigationSignIn, {
						location: Locations.stickieDrawer,
					})
					navigate('/account/signin')
				}}
				>
					Sign In
				</Styles.SignInButton>
			)}
		</Styles.AuthContainer>
	)
})

export default AuthBox
