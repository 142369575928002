
/* eslint-disable @typescript-eslint/no-unused-vars */

import firebase from 'gatsby-plugin-firebase'
import {
	find, toLower, sample, flatten, isNil, isEqual, uniqueId,
} from 'lodash'
import sha1 from 'sha1'
import uniqid from 'uniqid'
import React, { useCallback, useContext, useEffect } from 'react'
import Helmet from 'react-helmet'
import Scene from '../Scene'

import useSounds from '../hooks/useSounds'
import {
	MixpanelContext, AppUIEvent, AppStateEvent, AppCounter, AppLists,
} from '../../../common/tracking'
import {
	EffectTypeKeyed, SlideshowTypeKeyed, SlideTypeKeyed, StickerEffect, getAllEyes,
} from '../../../common/shared/npc-gen-1/schema'
import SlideShowHeader from '../Header'
import Sticker from '../Sticker'
import TimeOfDay from '../TimeOfDay'
import StickerFooter from '../FooterWithBidding'
import withLocation from '../../withLocation'
import useStickerStats from '../../../hooks/useStickerStats2'
// import useCustomizedSlideshow from '../../../hooks/useCustomizedSlideshow'
import useImagePreload from '../hooks/useImagePreload'
import useSticker from '../hooks/useSticker'
import SmartLayout from '../../../layouts/smartLayout'
// import CustomizedMoves from '../CustomizedMoves'
import '../../../styles/demo-style-8.css'
import '../../../styles/ticker.css'
import TwitterSummaryCard from '../../share/TwitterCards/Summary'
import {
	ChromeMode, SlideshowContext, SlideshowContextProps, SlideshowModeType,
} from '../SlideshowProvider'
// import { AuthContext, AuthContextProps } from '../User/AuthProvider'
import usePrevious from '../../../hooks/usePrevious'
// import LabelEffect from './Effects/LabelEffect'
import { getSocialProperties } from '../../../common/social'
import Menu from '../Menu/containerStoredValue'
import BrandLogo from '../../../images/stickies_brand.png'
import EffectsComponent from '../EffectImages'
// import { UIStages } from '../CompletedModal'
import GetInlineModal from '../GetInline'
import Messages from '../MessagesII'
import { AuthContext, AuthContextProps } from '../../User/AuthProvider'

import Preloader from '../Preloader'
import * as Styles from './styles'
import useCustomShare, { NAME_ITEM } from '../../../hooks/useCustomShare'
import useShareUIManager from '../../../hooks/useShareUIManager'
import useBidUIManager from '../../../hooks/useBidUIManager'
import useStickieValue from '../../../hooks/useStickieValue'
import useStickieUI from '../../../hooks/useStickieUI'
import useStickieState from '../hooks/useStickieState'
import useEnvironmentState from '../hooks/useEnvironmentState'
import useTimeout from '../../../hooks/useTimeout'

// import useStickiePrivateState from '../../../hooks/useStickiePrivateState'
import Actions from '../ActionsIII'
import {
	IMessage, TActions, TTransitionButtonProperties, TSlideProperties, TTimerProperties, TActionBody, ITransitionButton, IEnvironmentStateDefinition,
} from '../../../common/shared/npc-gen-1/sm-schema'
import Schema15 from '../../slideshows/Schema15'

interface IDefaultProps {
  displayMode?: SlideshowModeType
  startAtId?: string
}

type ComponentProps = {
  search: any
} & typeof defaultProps

const defaultProps:IDefaultProps = {}
interface IViewInfo {
  previousSlide: SlideTypeKeyed
  currentSlide: SlideTypeKeyed
  effects: Array<EffectTypeKeyed>
	// showMoves: boolean
	// showMessages: boolean
	// lastMessageIndex: Map<string, number>
	completed: boolean
}

interface IScene {
  url?: string
	color?: string
	tickId: string
}

const defaultStickerEffect: StickerEffect = {
	animation: {
		type: '',
	},
}

const getEffects = (slideshow: SlideshowTypeKeyed): Array<EffectTypeKeyed> => {
	// const effects = new Array<EffectTypeKeyed>()
	const effects: Array<EffectTypeKeyed> = flatten(slideshow.slides.map((slide: SlideTypeKeyed) => slide.effects))
	// console.log(`effects=${JSON.stringify(effects)}`)
	return effects
}

// const getStickerEffect = (effects:Array<EffectTypeKeyed>, slide: SlideTypeKeyed): EffectTypeKeyed | undefined => effects
//   .filter((effectObj) => effectObj.type === 'sticker')
//   .find((effectObj: EffectTypeKeyed) => findIndex(slide.effects, { id: effectObj.id }) >= 0)

const getStickerEffect = (slide: SlideTypeKeyed): StickerEffect => {
	const stickerEffect: EffectTypeKeyed | undefined = find(slide.effects, (effectObj) => effectObj.type === 'sticker')
	return stickerEffect && stickerEffect?.animation ? { animation: stickerEffect.animation } : defaultStickerEffect
}

const SlideShow = (props:ComponentProps):JSX.Element => {
	// doEmulator()

	const {
		search, displayMode = 'presentation',
	} = props
	const {
		/* name, */ scale, display,
	} = search

	const mixpanel = useContext(MixpanelContext)
	const { slideshow }:Partial<SlideshowContextProps> = useContext(SlideshowContext)
	// const { user }:Partial<AuthContextProps> = useContext(AuthContext)
	const {
		id,
		collection,
		slug,
		slides,
		ogTitle,
		// textboxOffset,
		sticker,
		startSlide,
		social,
		url,
		// createdOn,
	} = slideshow as SlideshowTypeKeyed

	const allEyes = getAllEyes(slideshow as SlideshowTypeKeyed)

	const { ogImageUrl } = social || { ogImageUrl: '' }
	const { twitterImageUrl } = social || { twitterImageUrl: '' }
	// const ago = moment(createdOn).utc().from(moment().utc())
	// console.log(`slideshow=${JSON.stringify(slideshow)}`)
	// console.log(`template ogImageUrl=${ogImageUrl}`)
	// console.log(`?display=${display}`)
	const socialProperties = getSocialProperties(ogTitle)

	const { user }:Partial<AuthContextProps> = useContext(AuthContext)
	const { uid } = user || {}
	const previousUid = usePrevious(uid)

	if (isNil(slideshow)) {
		throw new Error('Stickie not found')
	}

	const getSlide = useCallback((thisItemId:string): SlideTypeKeyed => {
		const slide = find(slides, (slideObj: SlideTypeKeyed) => toLower(slideObj.id) === toLower(thisItemId))
		if (isNil(slide)) {
			throw new Error(`Slide id "${thisItemId}" not found`)
		}
		return slide
	}, [slides])

	/*
	const getSlideByIndex = useCallback((index:number): SlideTypeKeyed => {
		const slide = slides[index]
		return slide
	}, [slides])
	*/

	// const firstSlide = getSlide(startAtId || startSlide)
	// const onlyHeight = useWindowHeight({ initialHeight: 856, leading: true })

	// console.log(`onlyHeight=${onlyHeight}`)
	// console.log(`search=${JSON.stringify(search)}`)
	// const customData = search

	/*
	const getUpdatedMessageMap = (lastIndexes:Map<string, number>, slideId:string, messageTexts: Array<IMessageTextsKeyed>): Map<string, number> => {
		if (lastIndexes.has(slideId)) {
			const lastIndex = lastIndexes.get(slideId) as number
			lastIndexes.set(slideId, lastIndex === messageTexts.length - 1 ? 0 : lastIndex + 1)
		} else {
			lastIndexes.set(slideId, 0)
		}
		return lastIndexes
	}
	*/

	const [headerIsOpen, setHeaderIsOpen] = React.useState<boolean>(false)

	// const [interactionHistory] = React.useState<Array<string>>([])
	const [slideshowMode, setSlideshowMode] = React.useState<SlideshowModeType>(displayMode)
	// Since we are doing SSR, we want the default mode of the page to have more elements hidden upon first render,
	// so the default chromeMode will be iosapp rather than web or else there is a UI glitch when displaying in the mobile app.  For example, the footer shows for a brief second.
	// const [chromeMode, setChromeMode] = React.useState<ChromeMode>('iosapp')

	const [chromeMode] = React.useState<ChromeMode>(() => (display === 'iosapp' ? 'iosapp' : 'web'))

	const [currentStateMessage, setCurrentStateMessage] = React.useState<IMessage | string>()
	const [currentStateTransitionButtons, setCurrentStateTransitionButtons] = React.useState<TTransitionButtonProperties>()
	const [currentStateSlide, setCurrentStateSlide] = React.useState<string>()

	// const [stateTimerInfo, setStateTimerInfo] = React.useState<object|undefined>()
	// const [currentStateTimer, setCurrentStateTimer] = React.useState<TTimerProperties>()

	const [lastSequentialMessageIndex, setLastSequentialMessageIndex] = React.useState<Record<string, number>>()

	// const [customizationMode, setCustomizationMode] = React.useState<ShareStageType>()
	const [showStickie, setShowStickie] = React.useState<boolean>(false)
	const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
	const [footerVisible, setFooterVisible] = React.useState<boolean>(true)

	// const [replayCount, setReplayCount] = React.useState<number>(0)
	// const [recipientName, setRecipientName] = React.useState<string | null>(null)
	// const [previewCustomMessages, setPreviewCustomMessages] = React.useState<Array<ICustomizedMessage>>()
	const [previewRecipientName, setPreviewRecipientName] = React.useState()
	const [emptyImageUrlsForGifHack, setEmptyImageUrlsForGifHack] = React.useState(true)
	const [viewInfo, setViewInfo] = React.useState<IViewInfo>(() => {
		const firstSlide = getSlide(startSlide)
		const currentSlide:SlideTypeKeyed = firstSlide
		const allEffects:Array<EffectTypeKeyed> = getEffects(slideshow)
		return {
			previousSlide: {} as SlideTypeKeyed,
			currentSlide,
			effects: allEffects,
			// showMoves: false,
			// showMessages: false,
			completed: false,
			// lastMessageIndex: new Map<string, number>(),
		} as IViewInfo
	})

	const [scene, setScene] = React.useState<IScene>({
		color: 'blue',
		// url: 'https://firebasestorage.googleapis.com/v0/b/stickes-dev.appspot.com/o/stickerImages%2Fdemos%2Fmocha-joe%2Fcaffeinated.jpg?alt=media&token=a57a343f-464e-43ab-8aba-74135b9728b6',
		tickId: uniqid(),
	} as IScene)

	// const userPrevious: firebase.User | null | undefined = usePrevious(user)
	// const viewInfoPrevious: IViewInfo = usePrevious(viewInfo)
	const { currentSlide, effects } = viewInfo
	const {
		views, interactions, userInteractions, setInteractions, setUserInteractions,
	} = useStickerStats(id)
	const [stopAllSounds, stopAllSlideSounds, playSlideSounds, initHowls, soundLoadingStatus, activelyPlaying, setIsSoundAllowed] = useSounds(slideshow)
	// const customMessageData = useCustomizedSlideshow(id, cid)
	const [isImagesLoaded] = useImagePreload(slideshow)

	// const shareButtonRef = React.useRef<HTMLDivElement | null>(null)
	const [stickerImageUrl, eyesUrl] = useSticker(viewInfo.currentSlide, sticker.cdnUrl || sticker.url, sticker.defaultEyesUrl)
	const {
		shareInfo, previousShareInfo, customizedMessages, setCustomizedMessages, setShareCount, shareCount, sharePool,
	} = useCustomShare(id, url, socialProperties.ogTitle, socialProperties.ogDescription, ogImageUrl)

	const {
		shareStage, previousShareStage, setShareStage, hasSeenModal, setHasSeenModal,
	} = useShareUIManager()

	const {
		stage: bidStage, setStage: setBidStage,
	} = useBidUIManager()

	// const editableMessages = getEditableMessageTexts(slideshow)
	// const isRecipientNameCustomizable = !isNil(editableMessages.find((item:IMessageTextsKeyed) => item.id === NAME_ITEM))
	// const isTextCustomizable = (editableMessages.filter((item:IMessageTextsKeyed) => item.id !== NAME_ITEM)).length > 0
	// const isCustomized = cid && cid.length > 0

	const { itemValue } = useStickieValue(id)

	const { executingAction, setExecutingAction } = useStickieUI()

	const { environmentState } = useEnvironmentState(collection)
	const { stickieState, setCutOffDate } = useStickieState(id, uid)
	const { state: apple } = stickieState

	const { nextStateOnEnter }:{nextStateOnEnter: TActions} = apple || { nextStateOnEnter: {} }
	const {
		setMessage, setTransitionButtons, setSlide, setSequentialMessage, setTimer,
	} = nextStateOnEnter

	const previousStickieState = usePrevious(stickieState)
	const previousStateSlide = usePrevious(currentStateSlide)
	const previousEnvironmentState = usePrevious(environmentState)

	/*
	useEffect(() => {
		if (!isEqual(previousStickieState, stickieState) && apple) {
			const { nextState } = apple
			// console.log('state change')
			mixpanel.track(AppStateEvent.stickieStateChange, {
				state: nextState,
			})
		}
	}, [previousStickieState, stickieState, mixpanel, apple])
	*/

	useEffect(() => {
		if (!isEqual(previousEnvironmentState, environmentState) && environmentState) {
			const { state: newEnvironmentState } = environmentState
			if (newEnvironmentState) {
				const { sceneUrl } = newEnvironmentState as IEnvironmentStateDefinition

				if (sceneUrl) {
					const img = new Image()
					img.onload = () => {
						setScene({
							url: sceneUrl,
							tickId: uniqid(),
						} as IScene)
					}
					img.onerror = () => {}
					img.src = sceneUrl
				}
			}
		}
	}, [previousEnvironmentState, stickieState, environmentState])

	useEffect(() => {
		// if (!isEqual(previousSetMessage, setMessage) && setMessage) {
		if (!isEqual(previousStickieState, stickieState) && setMessage) {
			const selectedMessage = sample(setMessage)
			// console.log(`selectedMessage = ${JSON.stringify(selectedMessage)}`)
			if (selectedMessage) {
				setCurrentStateMessage(selectedMessage)
			}
		}
	}, [previousStickieState, stickieState, setMessage])

	useEffect(() => {
		// if (!isEqual(previousSetSequentialMessage, setSequentialMessage) && setSequentialMessage && stickieState) {
		if (!isEqual(previousStickieState, stickieState) && setSequentialMessage && apple) {
			const { nextState } = apple
			const lastIndex = lastSequentialMessageIndex ? lastSequentialMessageIndex[nextState] : -1
			const nextIndex = (lastIndex + 1) >= (setSequentialMessage as Array<any>).length ? 0 : lastIndex + 1
			const selectedMessage = (setSequentialMessage as Array<any>)[nextIndex]

			/*
			console.log(`setSequentialMessage.length = ${JSON.stringify((setSequentialMessage as Array<any>).length)}`)
			console.log(`lastIndex = ${JSON.stringify(lastIndex)}`)
			console.log(`nextIndex = ${JSON.stringify(nextIndex)}`)
			console.log(`selectedMessage = ${JSON.stringify(selectedMessage)}`)
			*/

			if (selectedMessage) {
				setLastSequentialMessageIndex({ [nextState]: nextIndex })
				setCurrentStateMessage(selectedMessage)
			}
		}
	}, [previousStickieState, stickieState, lastSequentialMessageIndex, apple, setSequentialMessage])

	useEffect(() => {
		// if (!isEqual(previousSetTransitionButtons, setTransitionButtons) && setTransitionButtons) {
		if (!isEqual(previousStickieState, stickieState) && setTransitionButtons) {
			// console.log(`setTransitionButtons = ${JSON.stringify(setTransitionButtons)}`)
			setCurrentStateTransitionButtons(setTransitionButtons as TTransitionButtonProperties)
		}
	}, [previousStickieState, stickieState, setTransitionButtons])

	useEffect(() => {
		// if (!isEqual(previousSetSlide, setSlide) && setSlide) {
		if (!isEqual(previousStickieState, stickieState) && setSlide) {
			const selectedSlide = sample(setSlide as TSlideProperties)
			// console.log(`selectedSlide = ${JSON.stringify(selectedSlide)}`)
			if (selectedSlide) {
				setCurrentStateSlide(selectedSlide as string)
			}
		}
	}, [previousStickieState, stickieState, setSlide])

	/*
	useEffect(() => {
		// if (!isEqual(previousSetTimer, setTimer) && setTimer) {
		if (!isEqual(previousStateId, stateId) && setTimer) {
			console.log(`setTimer = ${JSON.stringify(setTimer)}`)
			setCurrentStateTimer(setTimer as TTimerProperties)
		}
	}, [previousStateId, stateId, setTimer])
	*/

	// const previousStateMessage = usePrevious(currentStateMessage)

	// const showPreloader = !(isImagesLoaded && soundLoadingStatus === 'preloaded') || executingAction
	const showPreloader = !(isImagesLoaded && soundLoadingStatus === 'preloaded')
	// const { currentState } = useStickiePrivateState(id)

	// const messageNodeRef = React.useRef()

	// console.log(`itemValue=${itemValue}`)

	/*
	useEffect(() => {
		if (isNil(display) || display !== 'iosapp') {
			setChromeMode('web')
		}
	}, [display])
	*/

	useEffect(() => {
		// console.log(previewRecipientName)
	}, [previewRecipientName])

	/*
	useEffect(() => {
		setRecipientName(previewRecipientName || name || undefined)
	}, [name, previewRecipientName])
	*/
	/*
	useEffect(() => {
		const { value } = customMessageData
		// console.log(`customMessageData=${JSON.stringify(customMessageData)}`)
		const name = value?.find((message:ICustomizedMessage) => message.id === NAME_ITEM)?.message ?? null
		// console.log(`name=[${name}]`)
		setRecipientName(previewRecipientName || name)
	}, [customMessageData, previewRecipientName])
*/
	// Runs once, after page load
	// useEffect(():void => {
	// mixpanel.track(AppStateEvent.stickieLoaded)
	// }, [mixpanel])

	/*
	// Should run after the user info loads
	useEffect(():void => {
		if (userPrevious?.uid !== user?.uid && (user?.isAnonymous && additionalUserInfo?.isNewUser)) {
			const trackingProps = {
				id,
				slug,
				// isNewUser: additionalUserInfo?.isNewUser,
				// isAnonymous: user?.isAnonymous,
				// uid: user?.uid,
			}

			mixpanel.register({
				uid: user?.uid,
			})

			mixpanel.track(
				'Authentication: New Anonymous User Created',
				trackingProps,
			)
		}
	}, [mixpanel, additionalUserInfo, user, slug, id, userPrevious])
	*/

	// Just register the slug and id once
	useEffect(():void => {
		mixpanel.register({
			id,
			slug,
		})

		mixpanel.track(AppStateEvent.stickieLoaded)

		// if (mixpanel.people) {
		mixpanel.people.increment(AppCounter.stickieVisits)
		// }
		mixpanel.people.increment({
			[`Visits: ${id}`]: 1,
		})
		mixpanel.people.union(AppLists.visitedStickies, id)
	}, [id, slug, mixpanel])

	/*
	useEffect(():void => {
		if (viewInfoPrevious && (viewInfoPrevious.hasReachedEnd !== viewInfo.hasReachedEnd) && viewInfo.hasReachedEnd) {
			mixpanel.track(AppStateEvent.stickieEndReached,
				{
					slideId: currentSlide.id,
				})

			if (cid && replayCount === 0) {
				const obj = {
					customId: cid,
					stickerId: id,
					history: interactionHistory,
				}
				// console.log('saving...', obj)
				firebase.functions().httpsCallable('eventEndReached')(obj)
			}
		}
	}, [viewInfo, mixpanel, slug, currentSlide, viewInfoPrevious, id, cid, interactionHistory, replayCount])
	*/
	// FIXME: need to render mixpanel effect based on hooks and previous property values to ensure better sequencing of moixpanel events

	/*
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const historyObject = {
				history: navigationHistory,
				currentSlideId: currentSlide.id,
			}
			if ((window as any).ReactNativeWebView) {
				(window as any).ReactNativeWebView.postMessage(JSON.stringify(historyObject), '*')
			}
		}
	}, [navigationHistory, viewInfo])
	*/
	useEffect(() => {
		// Implement slight delay here to prevent the mixpanel events from arriving out of order
		mixpanel.track(AppStateEvent.stickierSlideRendered,
			{
				slideId: currentSlide.id,
			})
	}, [mixpanel, currentSlide])

	useEffect(() => {
		if (emptyImageUrlsForGifHack) {
			setTimeout(() => {
				setEmptyImageUrlsForGifHack(false)
			}, 0)
		}
	}, [emptyImageUrlsForGifHack])

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const cmd = {
				command: shareStage === 'inactive' ? 'show-close-button' : 'hide-close-button',
			}

			if ((window as any).ReactNativeWebView) {
				(window as any).ReactNativeWebView.postMessage(JSON.stringify(cmd), '*')
			}
		}

		setFooterVisible(shareStage === 'inactive')
	}, [shareStage])

	useEffect(() => {
		// console.log(`previousShareInfo=${JSON.stringify(previousShareInfo)}`)
		// console.log(`shareInfo=${JSON.stringify(shareInfo)}`)
		if (!isEqual(previousShareInfo, shareInfo) && !isNil(previousShareInfo) && !isNil(shareInfo.id)) {
			setShareStage('share options')
		}
	}, [shareInfo, previousShareInfo, setShareStage])

	/*
	const onFinishedTyping = ():void => {
		setViewInfo({
			...viewInfo,
			showMoves: true,
			completed: viewInfo.currentSlide.moves.length === 0,
		})
		// if (viewInfo.currentSlide.moves.length === 0) {
		// setRefreshCount(refreshCount + 1)
		// }
	}
	*/

	/*
	const onFinishedStickerAnimating = ():void => {
		setViewInfo({
			...viewInfo,
			showMessages: true,
		})
	}
	*/
	// console.log(previewCustomMessages)
	/*
	const getMessageText = useCallback((vi:IViewInfo, cs:SlideTypeKeyed) :string => {
		const messageDataSet = previewCustomMessages || customMessageData.value || []
		const item:IMessageTextsKeyed = cs.messageTexts[vi.lastMessageIndex.get(cs.id) || 0]
		const existingCustomizedMessage = messageDataSet.find((message:ICustomizedMessage) => message.id === item.id)
		return (existingCustomizedMessage?.message || item.message)
	}, [customMessageData.value, previewCustomMessages])
	*/

	const launchIOSContextMenu = React.useCallback(
		() => {
			if (typeof window !== 'undefined') {
				const cmd = {
					command: 'open-context-menu',
				}
				if ((window as any).ReactNativeWebView) {
					(window as any).ReactNativeWebView.postMessage(JSON.stringify(cmd), '*')
				}
			}
		}, [],
	)

	const handleSoundIndicatorClick = React.useCallback(
		():void => {
			// console.log('clicked')
			setMenuOpen(true)
		},
		[],
	)

	/*
	const handleShareStageChange = React.useCallback(
		(shareStage:ShareStageType):void => {
			if (typeof window !== 'undefined') {
				const cmd = {
					command: shareStage === 'inactive' ? 'show-close-button' : 'hide-close-button',
				}

				if ((window as any).ReactNativeWebView) {
					(window as any).ReactNativeWebView.postMessage(JSON.stringify(cmd), '*')
				}
			}

			setFooterVisible(shareStage === 'inactive')
		},
		[],
	)
	*/

	const handleShareRequest = React.useCallback(() => {
		setShareCount(shareCount + 1)
	}, [setShareCount, shareCount])

	const handleBidRequest = React.useCallback(() => {
		setBidStage('sign up')
	}, [setBidStage])

	/*
	const handleUIStageChange = React.useCallback(
		(stage:UIStages):void => {
			setFooterVisible(stage !== 'options')
		},
		[],
	)
	*/

	const handleNavigateAway = React.useCallback(async ():Promise<any> => {
		stopAllSounds()
		return null
	}, [stopAllSounds])

	/*
	const doNextSlide = useCallback((nextSlide:SlideTypeKeyed, track = true):void => {

		if (track) {
			setNavigationHistory([viewInfo.currentSlide.id, ...navigationHistory])
		}

		// const newMessageIndex = getUpdatedMessageMap(viewInfo.lastMessageIndex, nextSlide.id, nextSlide.messageTexts || [])
		// const messageText = nextSlide.messageTexts.length > 0 ? getMessageText(viewInfo, nextSlide) : nextSlide.messageText

		// console.log(`next slide${nextSlide.id}`)

		setViewInfo({
			...viewInfo,
			currentSlide: nextSlide,
			previousSlide: viewInfo.currentSlide,
			// showMessages: false,
			// showMoves: messageText.length === 0,
			// lastMessageIndex: newMessageIndex,
		})

		setEmptyImageUrlsForGifHack(true)
		setRefreshCount(refreshCount + 1)
	}, [navigationHistory, refreshCount, viewInfo])
*/

	/*
	if (typeof window !== 'undefined') { // needed if SSR
		(window as any).stickies = {
			previous: ():boolean => {
				if (navigationHistory.length !== 0) {
					const previousSlideId = navigationHistory[0]
					if (navigationHistory.length > 1) {
						setNavigationHistory(takeRight(navigationHistory, navigationHistory.length - 1))
					} else if (navigationHistory.length === 1) {
						setNavigationHistory([])
					}
					const nextSlide:SlideTypeKeyed = getSlide(previousSlideId)
					doNextSlide(nextSlide, false)
					return true
				}
				return false
			},
			restart: ():boolean => {
				handleRestartRequest()
				return true
			},
			share: ():boolean => {
				handleShareRequest()
				return true
			},
		}
	}
*/
	/*
	const handleMove = (event: any): void => {
		const {
			targetids, movelabel, currentslide, trackid,
		}:{targetids:string; movelabel:string; currentslide:string; trackid:string} = event.currentTarget.dataset

		const targetIds = targetids.split(',')
		const targetId:string = sample(targetIds) || ''
		const nextSlide:SlideTypeKeyed = getSlide(targetId)
		const { points = 0 } = nextSlide as SlideTypeKeyed

		if (slideshowMode === 'presentation') {
			mixpanel.track(AppUIEvent.navigationStickieInteraction,
				{
					slideId: currentslide,
					move: movelabel,
					trackId: trackid,
				})

			setInteractions({
				count: interactions.count + 1,
				status: 'realtime',
			})

			setUserInteractions({
				...userInteractions,
				status: 'realtime',
				score: ((userInteractions.score || +0) + (+points)),
				interactions: {
					...userInteractions.interactions,
					[trackid]: {
						taps: (userInteractions?.interactions?.[trackid]?.taps || 0) + 1,
					},
				},
			} as IInteractionManager)

			interactionHistory.push(trackid)
			setInteractionHistory(interactionHistory)
		}

		doNextSlide(nextSlide)

		// console.log(`soundLoadingStatus=${soundLoadingStatus}`)
		if (soundLoadingStatus === 'preloaded') {
			initHowls(nextSlide.effects)
		} else {
			stopAllSlideSounds()
			playSlideSounds(nextSlide.effects)
		}
	}
	*/

	const handleStateChange = useCallback((targetId:string): void => {
		const nextSlide:SlideTypeKeyed = getSlide(targetId)
		const { points = 0 } = nextSlide as SlideTypeKeyed

		setInteractions({
			count: interactions.count + 1,
			status: 'realtime',
		})

		setUserInteractions({
			...userInteractions,
			status: 'realtime',
			score: ((userInteractions.score || +0) + (+points)),
			interactions: {
				...userInteractions.interactions,
			},
		})

		// console.log(`handleStateChange, targetId=${targetId}`)
		// doNextSlide(nextSlide)

		setViewInfo({
			...viewInfo,
			currentSlide: nextSlide,
			previousSlide: viewInfo.currentSlide,
			// showMessages: false,
			// showMoves: messageText.length === 0,
			// lastMessageIndex: newMessageIndex,
		})

		setEmptyImageUrlsForGifHack(true)

		// console.log(`soundLoadingStatus=${soundLoadingStatus}`)

		if (soundLoadingStatus === 'ready') {
			stopAllSlideSounds()
			playSlideSounds(nextSlide.effects)
		}
	}, [getSlide, interactions.count, playSlideSounds, setInteractions, setUserInteractions, soundLoadingStatus, stopAllSlideSounds, userInteractions, viewInfo])

	/*
	const sendMessage = (result:any):void => {
		const { message }:{message:IMessage} = result
		if (message) {
			messageNodeRef.current.sendMessage(message)
		}
	}
	*/

	const makeCloudCall = React.useCallback((event:string, dataPayload:object = {}):void => {
		setExecutingAction(true)
		const sendData = {
			stickieId: id,
			event,
			payload: dataPayload,
		}
		console.log(`fnActionsDoActionII=${JSON.stringify(sendData)}`)
		const fnDoAction = firebase.functions().httpsCallable('fnActionsDoActionII')
		fnDoAction(sendData)
			.then(({ data }) => {
				const { executedOn } = data as any
				// console.log(`data=${JSON.stringify(data)}`)
				// console.log(`executedOn=${JSON.stringify(executedOn)}`)
				setCutOffDate(new Date(executedOn))
			})
			.catch((err) => { console.error(err) })
			.finally(() => { setExecutingAction(false) })
	}, [id, setCutOffDate, setExecutingAction])

	const executeAction = React.useCallback(({ event, label, data }):void => {
		// const { event, label, data } = action
		// console.log(`executeAction=${event}`)
		mixpanel.track(AppUIEvent.navigationStickieInteraction,
			{
				eventName: event,
				dataPayload: data,
				cta: label,
				message: currentStateMessage,
				itemValue,
			})
		makeCloudCall(event, data)
	}, [mixpanel, currentStateMessage, itemValue, makeCloudCall])

	const actionClickHandler = React.useCallback((action:ITransitionButton):void => {
		setIsSoundAllowed(true)
		executeAction(action)
	}, [setIsSoundAllowed, executeAction])

	const interactionTapHandler = React.useCallback((event:any, info:any):void => {
	// console.log(`onTap event=${JSON.stringify(event)}`)
		const { point } = info
		// console.log(`onTap point=${JSON.stringify(point)}`)
		setIsSoundAllowed(true)
		executeAction({ event: 'tap', data: point })
	}, [executeAction, setIsSoundAllowed])

	const stateTimer = setTimer as TTimerProperties
	// console.log(`stateTimer=${JSON.stringify(stateTimer)}`)
	useTimeout(() => {
		const { event } = stateTimer
		// console.log('triggered timer')
		executeAction({ event: event[0] })
	}, stateTimer ? stateTimer.delay * 1000 : null, stickieState.stateId)

	/*
	useTimeout(() => {
		console.log('triggered background change')
		setScene({
			// url: 'https://firebasestorage.googleapis.com/v0/b/stickes-dev.appspot.com/o/stickerImages%2Fdemos%2Fcoachella-dust%2Fcoachella.jpg?alt=media&token=2d509064-7158-460c-b2ad-f4e7ecb0c583',
			color: 'red',
			tickId: uniqid(),
		} as IScene)
	}, 3000)
	*/

	/*
	useEffect(() => {
		if (!isEqual(previousShareStage, shareStage) && shareStage === 'customize') {
			handleRestartRequest()
		}
	}, [shareStage, previousShareStage, handleRestartRequest])
	*/

	/*
	const effectLabels = effects
		.filter((effectObj: EffectTypeKeyed) => effectObj.type === 'text')
		.map((effectObj:EffectTypeKeyed) => {
			const {
				id: effectId,
			} = effectObj
			// console.log(`effectyObj=${JSON.stringify(effectObj)}`)
			const animationInfo = getAnimation(effectObj)
			const showEffect = findIndex(currentSlide.effects, { id: effectId }) >= 0
			// const className = `${showEffect ? effectObj.className : 'hide-effect-at-start'}`
			// Hack to replay a single loop gif
			return showEffect ? <LabelEffect key={`label-effect-${effectId}`} {...{ ...effectObj, ...animationInfo }} /> : <></>
		})
*/

	// const effectVibrations = filter(effects, (effectObj: EffectTypeKeyed) => effectObj.type === 'vibration')
	// const slideVibration = last(effectVibrations)
	// if (slideVibration && typeof window !== 'undefined' && navigator) {
	//   // detect vibration support
	//   if ('vibrate' in navigator) {
	//   // navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate || null
	//   // if (navigator.vibrate)
	//     const vibrationPattern = slideVibration.vibration?.pattern
	//     // window?.navigator?.vibrate(vibrationPattern as Array<number>)
	//     navigator.vibrate([200])
	//   }
	// }

	useEffect(() => {
		if (uid && !isEqual(uid, previousUid)) {
			makeCloudCall('reset')
		}
	}, [previousUid, uid, makeCloudCall])

	const stickerEffect = getStickerEffect(viewInfo.currentSlide)

	useEffect(() => {
		if (!isEqual(previousStateSlide, currentStateSlide) && currentStateSlide) {
			handleStateChange(currentStateSlide)
		}
	}, [previousStateSlide, currentStateSlide, handleStateChange])

	/*
	function useTimeout(callback:any, delay:number):any {
		const savedCallback = React.useRef()

		// Remember the latest function.
		useEffect(() => {
			savedCallback.current = callback
		}, [callback])

		// Set up the interval.
		useEffect(() => {
			function tick():void {
				savedCallback.current()
			}
			if (delay !== null) {
				const timeoutId = setTimeout(tick, delay)
				return () => clearTimeout(timeoutId)
			}
		}, [delay])
	}
*/
	/*
	useEffect(() => {
		if (!isEqual(previousStickieState, stickieState) && setTimer) {
			const { delay, event } = setTimer as TTimerProperties
			const selectedEvent = sample(event) as string
			// let timeoutId:NodeJS.Timeout | undefined

			// if (uid) {
			console.log(`setTimeout=${JSON.stringify(setTimer)}`)

			useTimeout(() => {
				// Your custom logic here
    	console.log('timer triggered')
				makeCloudCall(selectedEvent)
			}, delay * 1000)

			setStateTimerInfo({ event: selectedEvent, delay: delay * 1000 })
			// const timeoutId = setTimeout(() => {
			// console.log('timer triggered')
			// makeCloudCall(selectedEvent)
			// }, delay * 1000)
			// return () => clearTimeout(timeoutId)
			// console.log(`created timeoutId=${timeoutId}`)
			// }
		}
	}, [makeCloudCall, previousStickieState, stickieState, setTimer])
*/

	// const compileTemplate(text:string, template:any)

	/*
	const getRenderedMessages = ():JSX.Element => {
		// const isCustomized = cid && cid.length > 0
		// const isCustomizedLoaded = customMessageData.status === 'fulfilled' && customMessageData.value.length > 0
		const isCustomizedLoaded = customMessageData.status === 'fulfilled'
		if (!isCustomized || isCustomizedLoaded) {
			const textRender = isNil(currentSlide.textRenderEffect) ? 'default' : currentSlide.textRenderEffect
			const messageTextTemplate = currentSlide.messageTexts.length > 0 ? getMessageText(viewInfo, currentSlide) : currentSlide.messageText

			const compiledMessage = Mustache.render(messageTextTemplate, {
				name: recipientName || currentSlide.defaultPlaceholderText,
				views: longNumber(views.count),
				interactions: longNumber(interactions.count),
			})

			// const template = Handlebars.compile(messageTextTemplate)
			// const compiledMessage = template({
			// name: recipientName || currentSlide.defaultPlaceholderText,
			// views: longNumber(views.count),
			// interactions: longNumber(interactions.count),
			// })

			const htmlMessage = ReactHtmlParser(compiledMessage)

			return (
				<Styles.StyledEventMessage textboxOffset={textboxOffset || '0px'} className={currentSlide.messageClassName}>
					{ textRender === 'default' && (
						<TextRenderFlyUp className={currentSlide.messageClassName} text={htmlMessage} onFinished={onFinishedTyping} key={`text-animation-${currentSlide.id}}`} />
					)}
					{ textRender === 'typewriter' && (
						<TextRenderTypewriter className={currentSlide.messageClassName} speed={50} text={compiledMessage} onFinished={onFinishedTyping} key="donotmodifyonrerender2" />
					)}
					{ textRender === 'typewriter-fast' && (
						<TextRenderTypewriter className={currentSlide.messageClassName} speed={30} text={compiledMessage} onFinished={onFinishedTyping} key="donotmodifyonrerender3" />
					)}

				</Styles.StyledEventMessage>
			)
		}
		return (<></>)
	}
	*/

	const handleRestartRequest = React.useCallback(():void => {
		mixpanel.track(AppUIEvent.navigationStickerRestartRequest)
		executeAction({ event: 'reset' })
	}, [mixpanel, executeAction])

	return (
		<>
			<Helmet>
				<title>{socialProperties.title}</title>
				<meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0" />
				<meta property="og:title" content={socialProperties.ogTitle} />
				<meta property="og:type" content="website" />
				<meta property="og:description" content={socialProperties.ogDescription} />
				<meta property="og:image" content={ogImageUrl} />
				<meta property="og:url" content={url} />
			</Helmet>
			<TwitterSummaryCard imgUrl={twitterImageUrl} title={socialProperties.ogTitle} description={socialProperties.ogDescription} />
			<SmartLayout scale={scale} scrollLock="hidden">
				<Styles.PreloadFontPhatomDiv300>abc</Styles.PreloadFontPhatomDiv300>
				<Styles.PreloadFontPhatomDiv400>abc</Styles.PreloadFontPhatomDiv400>
				<Styles.PreloadFontPhatomDiv500>abc</Styles.PreloadFontPhatomDiv500>
				<Styles.PreloadFontPhatomDiv700>abc</Styles.PreloadFontPhatomDiv700>
				<Styles.GlobalStyle />
				{
					(bidStage === 'sign up') && (
						<GetInlineModal initialOpenState onDismiss={() => { setBidStage('inactive') }} stickieId={id} itemName={ogTitle} />
					)
				}
				{!showStickie && (
					<Preloader
						show={showPreloader}
						onPreloadFinishedHiding={() => {
							mixpanel.track(AppStateEvent.stickierPreloaderHidden)
							setShowStickie(true)
						}}
					/>
				)}
				{showStickie && (
					<>
						{!headerIsOpen && (slideshowMode === 'presentation')
						&& (
							<Menu
								title={(slideshow?.name) || 'Stickie'}
								views={views.count}
								creatorIdOrHandle="blah"
								// {...{ logoUrl: sticker.cdnUrl, url }}
								{...{ logoUrl: BrandLogo, url }}
								onShareRequest={handleShareRequest}
								onReplayRequest={handleRestartRequest}
								isReplayVisible={false}
								onNavigateAway={handleNavigateAway}
								onBidHandler={handleBidRequest}
								onIOSContextMenu={launchIOSContextMenu}
								open={menuOpen}
								setOpen={setMenuOpen}
								shareEnabled={sharePool.length > 0}
								itemValue={itemValue}
							/>
						)}
						<SlideShowHeader
							// currentSlide={currentSlide.id}
							setPreviewRecipientName={setPreviewRecipientName}
							// setPreviewCustomMessages={setPreviewCustomMessages}
							setSlideshowMode={setSlideshowMode}
							handleRestart={handleRestartRequest}
							slideShowMode={slideshowMode}
							chromeMode={chromeMode}
							setOpen={setHeaderIsOpen}
							// shareRef={shareButtonRef}
							// handleShareStageChange={handleShareStageChange}
							onNavigateAway={handleNavigateAway}
							onIOSContextMenu={launchIOSContextMenu}
							soundIsPlaying={activelyPlaying > 0}
							handleSoundIndicatorClick={handleSoundIndicatorClick}
							shareInfo={shareInfo}
							customizedMessages={customizedMessages}
							setCustomizedMessages={setCustomizedMessages}
							// setShareCount={setShareCount}
							// shareCount={shareCount}
							shareStage={shareStage}
							setShareStage={setShareStage}
							// setCustomizationMode={setCustomizationMode}
							hasSeenModal={hasSeenModal}
							setHasSeenModal={setHasSeenModal}
						/>

						<Styles.StyledSiteContent
							// HACK to see if we can get rid of refreshCount
							// key={`refresh-${refreshCount}`}
							// isCustomizationMode={(shareStage === 'customize') && isTextCustomizable}
							isCustomizing={shareStage === 'customize'}
							animate={(shareStage === 'customize') ? 'customize' : 'normal'}
						>

							<Scene url={scene?.url} color={scene?.color} sceneId={scene.tickId} />

							{/* <Styles.StickerBackgroundContainer url={sticker.background?.cdnUrl || sticker.background?.url} color={sticker.background?.color} /> */}

							{/* <CompletedModal
								key={`refresh-modal-${viewInfo.completed ? '1' : '0'}`}
								uiStage={footerVisible && viewInfo.completed ? 'burndown' : 'idle'}
								onReplay={handleRestartRequest}
								onShare={handleShareRequest}
								handleUIStageChange={handleUIStageChange}
								shareEnabled={sharePool.length > 0}
							/> */}

							<Messages
								message={currentStateMessage}
								waitingForReply={executingAction}
							/>

							{/* <Styles.StickieName>{ogTitle}</Styles.StickieName> */}

							<Styles.ContentRegion>
								{/* <Styles.StickerBackgroundContainer url={sticker.background?.cdnUrl || sticker.background?.url} color={sticker.background?.color}> */}
								<Styles.DrawingCanvas>

									<EffectsComponent
										effects={effects}
										currentSlide={currentSlide}
										emptyImageUrlsForGifHack={emptyImageUrlsForGifHack}
									/>

									<Sticker
										onTap={interactionTapHandler}
										currentUrl={stickerImageUrl}
										allEyes={allEyes}
										eyesUrl={eyesUrl}
										{...sticker}
										{...stickerEffect}
										// key={`sticker-on-slide-id-${currentSlide.id}}`}
										// onFinished={onFinishedStickerAnimating}
										onFinished={() => {}}
									/>

								</Styles.DrawingCanvas>

							</Styles.ContentRegion>
							<Styles.LowerSection>

								<Actions
									transitionButtons={currentStateTransitionButtons}
									actionClickHandler={actionClickHandler}
									disabled={executingAction}
								/>

								{/* {footerVisible && (
									<StickerFooter
										shareEnabled={sharePool.length > 0}
										onShareHandler={handleShareRequest}
										onBidHandler={handleBidRequest}
										itemValue={itemValue}
									/>
								)} */}
								<TimeOfDay environmentState={environmentState} />
							</Styles.LowerSection>
						</Styles.StyledSiteContent>

					</>
				)}
			</SmartLayout>
		</>
	)
}

export default withLocation(SlideShow)
