import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
	font-weight: 700;
	font-size: 12px;
	position: absolute;
	z-index: 999999;
	top: 3px;
	right: 10px;
	height: 20px;
	width: 20px;
	color: var(--brandColor); 
`

const Points = (props:any):JSX.Element => {
	const { diff } = props
	return (
		<Container
			initial={{ opacity: 0.0 }}
			animate={{
				opacity: [1, 0],
			}}
			transition={{
				delay: 0,
				duration: 1.4,
				times: [0.4, 1],
			}}
		>
			{
				diff > 0 ? `+${diff}` : ''
			}
		</Container>
	)
}

export default Points
