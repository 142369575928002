import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import * as Styles from './styles'
import StoredValue from '../StoredValue2'
// import { siteData } from '../../../common/siteData'
import AuthBox from './AuthBox'
import { AuthContext, AuthContextProps } from '../../User/AuthProvider'
import Logo from '../../../images/svg/madewithstickies.svg'

import Replay from '../../../images/svg/replay_black.svg'
import Share from '../../../images/svg/send_black.svg'
import Home from '../../../images/svg/home.svg'
import { siteData } from '../../../common/siteData'

import { MixpanelContext, AppUIEvent, Locations } from '../../../common/tracking'

interface ComponentProps {
  open: boolean
	// setOpen: Function
	// title: string
	// views: number
	// creatorIdOrHandle:string
	onShareRequest:Function
	onReplayRequest:Function
	onNavigateAway:Function
	shareEnabled: boolean
	onBidHandler:Function
	itemValue: number
}

const Menu:React.FC<ComponentProps> = (props) => {
	const {
		open, onShareRequest, onNavigateAway, shareEnabled, onReplayRequest,
	} = props
	const isHidden = !!open
	const { user, Logout }:Partial<AuthContextProps> = React.useContext(AuthContext)
	const mixpanel = useContext(MixpanelContext)

	const onLogout = React.useCallback(
		() => (Logout as Function)(),
		[Logout],
	)

	return (
		<Styles.StyledMenu open={open} aria-hidden={!isHidden}>
			<Styles.AuthContainer>
				<AuthBox
					profileUrl={user?.photoURL || undefined}
					name={user?.emailVerified ? user?.displayName || user?.email : undefined}
					onLogout={onLogout}
					isVerified={user?.emailVerified || false}
				/>
			</Styles.AuthContainer>
			{/* <Styles.BidContainer> */}
			<StoredValue {...props} />
			{/* </Styles.BidContainer> */}

			<Styles.LinkSection>
				{/* <Styles.StyledLink to="/">
					More By This Creator
				</Styles.StyledLink> */}
				<Styles.StyledButton
					onClick={() => {
						mixpanel.track(AppUIEvent.navigationReplay, {
							location: Locations.stickieDrawer,
						})
						onReplayRequest()
					}}
					disabled={false}
				>
					<Styles.Icon><Replay width={40} height={40} /></Styles.Icon>
					<Styles.Text>Respawn</Styles.Text>
				</Styles.StyledButton>
				<Styles.StyledButton
					disabled={!shareEnabled}
					onClick={() => {
						mixpanel.track(AppUIEvent.navigationShare, {
							location: Locations.stickieDrawer,
							cta: siteData.shareButtonText,
						})
						onShareRequest()
					}}
				>
					{/* Customize / Share */}
					<Styles.Icon><Share width={40} height={40} /></Styles.Icon>
					{/* <Styles.Text>Customize / Share</Styles.Text> */}
					<Styles.Text>{siteData.shareButtonText}</Styles.Text>
				</Styles.StyledButton>
				{/* <Styles.StyledAnchor href={siteData.iOSTestFlightUrl} target="_blank">
					Make Your Own
				</Styles.StyledAnchor> */}
				<Styles.StyledLink
					to="/"
					onClick={(event) => {
						event.preventDefault()
						mixpanel.track(AppUIEvent.navigationHome, {
							location: Locations.stickieDrawer,
						})
						onNavigateAway().then(() => navigate('/', { replace: true }))
					}}
				>
					<Styles.Icon><Home width={40} height={40} /></Styles.Icon>
					<Styles.Text>Make Offer</Styles.Text>
				</Styles.StyledLink>
			</Styles.LinkSection>
			<Styles.LogoContainer><Logo /></Styles.LogoContainer>
		</Styles.StyledMenu>
	)
}

export default Menu
