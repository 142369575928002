import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, Variants } from 'framer-motion'
// import Lottie from 'lottie-react'
import { device, desktopDemoDevice } from '../../../utils/device'
// import preloaderLottieFile from '../../../../static/media/lottie/lf30_kcwjrjz6.json'
import preloaderGifFile from '../../../images/playlovebid.gif'

const PreloadContainer = styled(motion.div)`
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	/* background-image: ${'url(/media/svg/cloud-dark.svg)'}; */
	background-color: var(--gray1);
	background-color: #000;

	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.height}px;
	}
`

const PreloadImage = styled.img`
	object-fit: contain;

`

/*
export const LogoContainer = styled(motion.div)`
	width: 150px;
	height: 150px;
	object-fit: contain;
`

export const LoadingMessage = styled.div`
	width: 200px;
	height: 20px;
	text-align: center;
	font-weight: 400;
	color: var(--white1);
	text-transform: uppercase;
	font-size: 14px;
`

export const LoadingMessage2 = styled.div`
	width: 200px;
	height: 20px;
	text-align: center;
	font-weight: 400;
	color: var(--white1);
	text-transform: uppercase;
	font-size: 24px;
`
*/

interface ComponentProps {
	show: boolean
	onPreloadFinishedHiding: Function
}

const Preloader:React.FC<ComponentProps> = (props) => {
	const { show, onPreloadFinishedHiding } = props

	const [startTime] = useState<Date>(new Date())
	const [startHidingPreloader, setStartHidingPreloader] = useState<boolean>(false)

	const containerVariants:Variants = {
		hide: {
			opacity: 0,
		},
		show: {
			opacity: 1,
		},

	}

	React.useEffect(() => {
		if (!show) {
			const numberOfMlSeconds = startTime.getTime()
			const addMlSeconds = /* 2500 */ 1500
			const curMlSeconds = new Date().getTime()

			if (curMlSeconds > numberOfMlSeconds + addMlSeconds) {
				setStartHidingPreloader(true)
			} else {
				const triggerinMl = ((numberOfMlSeconds + addMlSeconds) - curMlSeconds)
				setTimeout(() => {
					setStartHidingPreloader(true)
				}, triggerinMl)
			}
		}
	}, [show, startTime])

	return (
		<PreloadContainer
			initial="show"
			animate={startHidingPreloader ? 'hide' : 'show'}
			variants={containerVariants}
			onAnimationComplete={() => {
				if (startHidingPreloader) {
					onPreloadFinishedHiding()
				}
			}}
		>
			<PreloadImage src={preloaderGifFile} />
			{/* // <Lottie
			// style={{
			// width: '100%', height: '100%',
			// }}
			// autoplay
			// animationData={preloaderLottieFile}
			// loop={false}
			// /> */}
		</PreloadContainer>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.show === nextProps.show)
export default React.memo(Preloader, areEqual)
