import React, { useCallback } from 'react'
import numeral from 'numeral'
import firebase from 'gatsby-plugin-firebase'
import { debounce, find, isNil } from 'lodash'
import uniqid from 'uniqid'
import * as Styles from './styles'
import { AuthContext, AuthContextProps } from '../../User/AuthProvider'
import { SlideshowContext, SlideshowContextProps } from '../SlideshowProvider'
import { IScoreboard, SlideshowTypeKeyed, ILeader } from '../../../common/shared/schema12'

interface ComponentProps {
  leaders: Array<ILeader>
}

const LeaderList = ({
	leaders,
}:ComponentProps):JSX.Element => {
	const { slideshow }:Partial<SlideshowContextProps> = React.useContext(SlideshowContext)
	const { id, scoreboard } = slideshow as SlideshowTypeKeyed
	const { scoreFormat } = scoreboard as IScoreboard
	const { user }:Partial<AuthContextProps> = React.useContext(AuthContext)
	const [myNickName, setMyNickName] = React.useState<string>(() => find(leaders, (leader:ILeader) => !isNil(leader.uid))?.nickName || '')
	const { uid } = { ...user }

	const debouncedSave = useCallback(
		debounce((nextValue) => {
			// console.log(`debounce:${nextValue}`)
			firebase
				.firestore()
				.collection('stickerStats')
				.doc(id)
				.collection('users')
				.where('uid', '==', uid || null)
				.get()
				.then((result:firebase.firestore.QuerySnapshot) => {
					result.docs[0].ref.set({ nickName: nextValue }, { merge: true })
				})
		}, 1000),
		[], // will be created only once initially
	)

	const handleTextBoxChange = (event:any):void => {
		const newName = event.target.value
		setMyNickName(newName)
		debouncedSave(newName)
	}

	return (
		<Styles.Container>
			<Styles.HeaderContainer>
				<Styles.HeaderImageContainer>
					<Styles.HeaderImage src={slideshow?.defaultImage} alt="" />
				</Styles.HeaderImageContainer>
				<Styles.HeaderTitle>{slideshow?.name || 'Stickie'}</Styles.HeaderTitle>
				<Styles.HeaderTagline>{slideshow?.scoreboard?.tagline}</Styles.HeaderTagline>
			</Styles.HeaderContainer>
			<Styles.Table>
				<Styles.TableHeaderRow key="upper">
					<Styles.TableRowItem>Rank</Styles.TableRowItem>
					<Styles.TableRowItem>Name</Styles.TableRowItem>
					<Styles.TableRowItem>Score</Styles.TableRowItem>
				</Styles.TableHeaderRow>
				{leaders.map(({
					rank, score: leaderScore, nickName: leaderNickName, uid: uidOfLeader,
				}) => (

					<Styles.TableRow key={isNil(uidOfLeader) ? uniqid() : 'myNickName'}>
						<Styles.TableRowItem>{`#${rank}`}</Styles.TableRowItem>
						{
							isNil(uidOfLeader)
								? (
									<Styles.TableRowItem>{leaderNickName}</Styles.TableRowItem>
								) : (
									<Styles.TableRowItemMe>
										<Styles.Textbox type="text" placeholder="Your Name" value={myNickName} onChange={handleTextBoxChange} maxLength={12} />
									</Styles.TableRowItemMe>
								)
						}
						<Styles.TableRowItem>{`${numeral(leaderScore).format(scoreFormat)}`}</Styles.TableRowItem>
					</Styles.TableRow>

				))}
			</Styles.Table>
		</Styles.Container>
	)
}

export default LeaderList
