import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { isNil } from 'lodash'
import { device, desktopDemoDevice } from '../../../utils/device'

interface ComponentProps {
	sceneId: string
	url?: string
	color?: string
}

const Container = styled.div`
	position:absolute;
	left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`

const Background = styled(motion.div)<ComponentProps>`
	position:absolute;
	z-index: 0;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 100vw;
	height: 100vh;
	background-image: ${(props) => (!isNil(props.url) ? `url(${props.url})` : 'none')};
	background-color: ${(props) => (!isNil(props.color) ? `${props.color}` : 'transparent')};
	background-size: cover;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.height}px;
	}
`
/*
const GradientBackground = styled.div`
	position:absolute;
	z-index: 100;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 100vw;
	height: 100vh;
	background: radial-gradient(ellipse at top,var(--gray3), transparent);
	@media ${device.isTabletOrLarger} {
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.height}px;
	}
`
*/

const Scene: React.FC<ComponentProps> = (props) => {
	const {
		sceneId,
	} = props

	return (
		<Container>
			<AnimatePresence initial={false}>
				<Background
					{...props}
					key={sceneId}
					animate={{ opacity: 1 }}
					initial={{ opacity: 0 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 2.0 }}
				/>
			</AnimatePresence>
			{/* <GradientBackground /> */}
		</Container>
	)
}

Scene.defaultProps = {
	url: '',
	color: '',
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.sceneId === nextProps.sceneId)

export default React.memo(Scene, areEqual)
