import { useEffect, useState } from 'react'
import { SlideshowTypeKeyed, getAllImages } from '../../../common/shared/npc-gen-1/schema'

// const useImagePreload = (imagesUrls:Array<string>):any => {
const useImagePreload = (slideshow: SlideshowTypeKeyed):any => {
	const [isImagesLoaded, setIsImagesLoaded] = useState<boolean>(false)

	const cacheImages = async (imagesUrls:Array<string>):Promise<void> => {
		const uniqUrls: Array<string> = new Array<string>()

		const promises = imagesUrls.map((src:string):Promise<any> => {
			if (!uniqUrls.find((item:string) => item === src)) {
				return new Promise((resolve, reject) => {
					uniqUrls.push(src)
					const img = new Image()
					img.onload = () => resolve(null)
					img.onerror = reject
					img.src = src
				})
			} return Promise.resolve(null)
		})

		await Promise.all(promises)
		setIsImagesLoaded(true)
	}

	useEffect(() => {
		cacheImages(getAllImages(slideshow))
	}, [])

	return [isImagesLoaded]
}

export default useImagePreload
