import React, { useEffect } from 'react'
// import { navigate } from '@reach/router'
// import { navigate } from 'gatsby'
// import { isEmpty } from 'lodash'
import styled from 'styled-components'
import Share from '../Share'
import Scoreboard from '../Scoreboard'
import { ICustomizedMessage, SlideshowTypeKeyed } from '../../../common/shared/schema12'
import {
	SlideshowContext, SlideshowContextProps, SlideshowModeType, ChromeMode,
} from '../SlideshowProvider'
import { device, desktopDemoDevice } from '../../../utils/device'
import * as Styles from './styles'
import CloseLight from '../../../images/svg/close_white.svg'
import SoundIndicator from '../SoundIndicator'
import { IShareInfo } from '../../../hooks/useCustomShare'
import { ShareStageType } from '../../../hooks/useShareUIManager'

export const StyledSlideShowHeader = styled.div`
  width: 100%;
  height: 51px;
	position: fixed;
  top: 0;
  z-index: 300000;
	background-color: transparent;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		/* Safari  */
		position: -webkit-sticky; 
		position: sticky;
	}
`

type ComponentProps = {
	// currentSlide: string
	slideShowMode: SlideshowModeType
	setSlideshowMode: any
	setPreviewRecipientName: any
	// setPreviewCustomMessages: any
	handleRestart: Function
	setOpen: Function
	onNavigateAway: Function
	// shareRef: any
	chromeMode: ChromeMode
	soundIsPlaying:boolean
	onIOSContextMenu: Function
	handleSoundIndicatorClick: Function
	shareInfo: IShareInfo
	setCustomizedMessages: (a:Array<ICustomizedMessage>)=>void
	// setShareCount: (nextShareCount:number)=>void
	// shareCount: number
	customizedMessages: Array<ICustomizedMessage>
	shareStage: ShareStageType
	setShareStage: any
	hasSeenModal: boolean
	setHasSeenModal: any
}

const SlideShowHeader = (props:ComponentProps):JSX.Element => {
	const { slideshow }:Partial<SlideshowContextProps> = React.useContext(SlideshowContext)
	const { scoreboard } = slideshow as SlideshowTypeKeyed
	const { slideShowMode } = props
	const { setOpen } = props
	const { chromeMode } = props
	const {
		setSlideshowMode, setPreviewRecipientName, /* setPreviewCustomMessages, */ handleRestart, onNavigateAway,
		soundIsPlaying, onIOSContextMenu, handleSoundIndicatorClick, shareInfo, setCustomizedMessages, customizedMessages,
		shareStage, setShareStage, hasSeenModal, setHasSeenModal,
	} = props

	const [headerIsOpen, _setHeaderIsOpen] = React.useState<boolean>(false)
	const [scoreboardIsOpen, _setScoreboardIsOpen] = React.useState<boolean>(false)
	const [isCloseButtonVisible, setIsCloseButtonVisible] = React.useState<boolean>(false)

	const setHeaderIsOpen = React.useCallback((val:boolean):void => {
		setOpen(scoreboardIsOpen || val)
		_setHeaderIsOpen(val)
	}, [scoreboardIsOpen, setOpen])

	const setScoreboardIsOpen = React.useCallback((val:boolean):void => {
		setOpen(headerIsOpen || val)
		_setScoreboardIsOpen(val)
	}, [headerIsOpen, setOpen])

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const url = new URL(window.location.href)
			// const { referrer } = window.document
			// setIsCloseButtonVisible(!isEmpty(referrer) /* && (window.history.length > 1) */ && slideShowMode === 'presentation')
			setIsCloseButtonVisible(url.searchParams.has('showback') && slideShowMode === 'presentation')
		}
	}, [slideShowMode])

	// console.log(`window.history.length=${window.history.length}`)
	// console.log(`slideShowMode=${slideShowMode}`)
	// console.log(`isCloseButtonVisible=${isCloseButtonVisible}`)
	return (
		<StyledSlideShowHeader>
			{(!!scoreboard && slideShowMode === 'presentation') && <Scoreboard setOpen={setScoreboardIsOpen} />}

			{ slideShowMode === 'presentation' && <SoundIndicator isPlaying={soundIsPlaying} handleClick={handleSoundIndicatorClick} chromeMode={chromeMode} onIOSContextMenu={onIOSContextMenu} />}

			{ (isCloseButtonVisible && chromeMode === 'web') && (
				<Styles.CloseContainer
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ delay: 0.5 }}
				>
					<Styles.CloseLink
						to="/"
						onClick={(event) => { event.preventDefault(); onNavigateAway().then(() => window.history.back() /* navigate('/', { replace: true }) */) }}
					>
						<CloseLight width={40} height={40} />
					</Styles.CloseLink>
				</Styles.CloseContainer>
			)}
			{(slideShowMode === 'presentation' || slideShowMode === 'preview')
			&& (
				<Share
					// currentSlide={currentSlide}
					// slideShowMode={slideShowMode}
					setSlideshowMode={setSlideshowMode}
					setPreviewRecipientName={setPreviewRecipientName}
					// setPreviewCustomMessages={setPreviewCustomMessages}
					handleRestart={handleRestart}
					setOpen={setHeaderIsOpen}
					// ref={shareRef}
					shareInfo={shareInfo}
					customizedMessages={customizedMessages}
					setCustomizedMessages={setCustomizedMessages}
					// setShareCount={setShareCount}
					// shareCount={shareCount}
					shareStage={shareStage}
					setShareStage={setShareStage}
					hasSeenModal={hasSeenModal}
					setHasSeenModal={setHasSeenModal}
				/>
			)}

		</StyledSlideShowHeader>
	)
}
export default SlideShowHeader
