// import React, { useImperativeHandle, useEffect, useState } from 'react'
import React from 'react'
import MessageList from './MessageList'
import { IMessage } from '../../../common/shared/npc-gen-1/sm-schema'
import * as Styles from './styles'
import waitingForReplyGif from '../../../images/dots.gif'

interface ComponentProps {
	message: IMessage | string | undefined
	waitingForReply: boolean
}

const Example:React.FC<ComponentProps> = (props) => {
	const { message, waitingForReply } = props

	return (
		<Styles.Container>
			<MessageList message={message} />
			{
				waitingForReply && (
					<Styles.WaitingForReplyGif src={waitingForReplyGif} />
				)
			}
		</Styles.Container>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.message === nextProps.message && prevProps.waitingForReply === nextProps.waitingForReply)
export default React.memo(Example, areEqual)
