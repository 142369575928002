import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

export const SoundIndicator = styled(motion.button)<{darken:boolean}>`
	position: absolute;
	top: 8px;
  left: 8px;
	display: flex;
	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;

  width: 40px;
  height: 40px;
	background-color:${(props) => (props.darken ? 'var(--gray1At40Percent)' : 'transparent')};
  border: none;
	cursor: pointer;
	border-radius: 50%;
  padding: 0;
	z-index: 10;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	/* border: 1px dotted orange; */
	&:focus {
		outline: none;
	}

	@media ${device.isTabletOrLarger} { 
		left:${desktopDemoDevice.borderWidth}px;
	}

`
