import { useCallback, useEffect, useState } from 'react'
import { Howl } from 'howler'
import axios from 'axios'
import { Buffer } from 'buffer'
import { compact, flatten, isEqual } from 'lodash'
import {
	EffectTypeKeyed, ISound, SlideshowTypeKeyed, SlideTypeKeyed,
} from '../../../common/shared/npc-gen-1/schema'
import usePrevious from '../../../hooks/usePrevious'

interface ISoundEffect {
	effectIds: Array<string>
	base64: string
  howl?: Howl
	soundId?: any
	loop: boolean
}

type Status = 'preloading' | 'preloaded' | 'pending' | 'initializing' | 'ready'

async function getBase64(url:string):Promise<string> {
	const response = await axios
		.get(url, {
			responseType: 'arraybuffer',
		})
	return Buffer.from(response.data, 'binary').toString('base64')
}

const useSounds = (slideshow: SlideshowTypeKeyed):any => {
	const [sounds, setSounds] = useState<Array<ISoundEffect>>(new Array<ISoundEffect>())
	const [activelyPlaying, setActivelyPlaying] = useState<number>(0)
	const [soundLoadingStatus, setSoundLoadingStatus] = useState<Status>('pending')
	const [isSoundAllowed, setIsSoundAllowed] = useState<boolean>(false)

	const previousIsSoundAllowed = usePrevious(isSoundAllowed)
	const playSlideSounds = useCallback((slideEffects:Array<EffectTypeKeyed>, allSounds:Array<ISoundEffect> = sounds):void => {
		slideEffects
			.filter((effectObj: EffectTypeKeyed) => effectObj.type === 'sound')
			.forEach((effectObj: EffectTypeKeyed) => {
				// console.log('play sound')
				const sound = allSounds.find((item:ISoundEffect) => item.effectIds.includes(effectObj.id))
				if (sound) {
					if (sound?.howl?.playing(sound.soundId) && sound.loop) {
						return
					}
					const delay = (effectObj.sound?.delay || 0) * 1000
					setTimeout(() => {
						if (isSoundAllowed) {
							sound.soundId = sound?.howl?.play()
						}
					}, delay)
				}
			})
	}, [isSoundAllowed, sounds])

	const stopAllSounds = ():void => {
		sounds.forEach((sound:ISoundEffect) => {
			sound?.howl?.pause()
			sound?.howl?.seek(0)
		})
	}

	const stopAllSlideSounds = ():void => {
		sounds.forEach((sound:ISoundEffect) => {
			if (!sound.loop) {
				sound?.howl?.pause()
				sound?.howl?.seek(0)
			}
		})
	}

	const initHowls = useCallback((slideEffects?:Array<EffectTypeKeyed>):void => {
		// console.log('initHowls')
		setSoundLoadingStatus('initializing')
		const newSounds = new Array<ISoundEffect>()
		sounds.forEach((sound:ISoundEffect) => {
			newSounds.push({
				...sound,
				howl: new Howl({
					src: sound.base64,
					html5: true,
					loop: sound.loop ? sound.loop : false,
					onplay: () => {
						setActivelyPlaying(activelyPlaying + 1)
						// console.log('useSound, play')
					},
					onstop: () => {
						setActivelyPlaying(activelyPlaying - 1)
						// console.log('useSound, stop')
					},
					onpause: () => {
						setActivelyPlaying(activelyPlaying - 1)
						// console.log('useSound, pause')
					},
					onend: () => {
						setActivelyPlaying(activelyPlaying - 1)
						// console.log('useSound, end')
					},
				}),
			} as ISoundEffect)
		})

		setSoundLoadingStatus('ready')

		if (slideEffects) {
			playSlideSounds(slideEffects, newSounds)
		}

		setSounds(newSounds)
	}, [activelyPlaying, playSlideSounds, sounds])

	const preloadAllSounds = async ():Promise<Array<ISoundEffect>> => {
		const uniqUrls: Array<string> = new Array<string>()
		const allData = await Promise.all(slideshow.slides.map(async (slide:SlideTypeKeyed) => Promise.all(slide.effects.map(async (effect:EffectTypeKeyed) => {
			if (effect.type === 'sound') {
				const { url } = effect.sound as ISound
				if (!uniqUrls.find((item:string) => item === url)) {
					uniqUrls.push(url)
					const base64 = `data:audio/mp3;base64,${await getBase64(url)}`
					const allOtherEffectIdsWithSameSound = compact(
						flatten(slideshow.slides.map(
							(slide2:SlideTypeKeyed) => slide2.effects.map(
								(effect2:EffectTypeKeyed) => (effect2.sound?.url === url && effect2.id !== effect.id ? effect2.id : null),
							),
						)),
					)
					return {
						effectIds: [effect.id, ...allOtherEffectIdsWithSameSound],
						base64,
						loop: effect?.sound?.loop ?? false,

					} as ISoundEffect
				}
				return null
			}
			return null
		}))))

		return compact(flatten(allData))
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			(async () => {
				setSoundLoadingStatus('preloading')
				const data = await preloadAllSounds()
				setSounds(data)
				setSoundLoadingStatus('preloaded')
			})()
		}
	}, [])

	useEffect(() => {
		if (!isEqual(previousIsSoundAllowed, isSoundAllowed) && isSoundAllowed) {
			initHowls()
		}
	}, [previousIsSoundAllowed, isSoundAllowed, initHowls])

	return [stopAllSounds, stopAllSlideSounds, playSlideSounds, initHowls, soundLoadingStatus, activelyPlaying, setIsSoundAllowed]
}

export default useSounds
