import React from 'react'
import { SlideshowTypeKeyed } from '../../common/shared/npc-gen-1/schema'

export type SlideshowModeType = 'presentation' | 'preview' | 'draft:preview' | 'draft:test'
export type ChromeMode = 'web' | 'iosapp'
export type SlideshowContextProps = {
  slideshow: SlideshowTypeKeyed
};

export const SlideshowContext = React.createContext<Partial<SlideshowContextProps>>({})
export const SlideshowProvider = ({ slideshow, children }: {slideshow: SlideshowTypeKeyed | undefined;children:any}):JSX.Element => (
	<SlideshowContext.Provider
		value={{
			slideshow,
			// mode: slideshowMode,
			// setMode,
		}}
	>
		{children}
	</SlideshowContext.Provider>
)
