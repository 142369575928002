
import React, { useRef, useContext } from 'react'
import Lottie from 'lottie-react'
import * as Styles from './styles'
import soundWhiteDots from '../../../../static/media/lottie/lf30_editor_1gedfthi_white_dots.json'
import { ChromeMode } from '../SlideshowProvider'
import { AppUIEvent, Locations, MixpanelContext } from '../../../common/tracking'

interface ComponentProps {
  isPlaying: boolean
	handleClick: Function
	onIOSContextMenu: Function
	chromeMode: ChromeMode
}

const SoundIndicator = (props:ComponentProps) :any => {
	const {
		isPlaying, chromeMode, onIOSContextMenu, handleClick,
	} = props
	const lottieRef = useRef(null)
	const mixpanel = useContext(MixpanelContext)
	const changeState = (theRef:any, play:boolean):void => {
		if (theRef.current !== null) {
			if (play) {
				theRef.current.goToAndPlay(0, true)
			} else {
				theRef.current.goToAndStop(0, true)
			}
		}
	}

	changeState(lottieRef, isPlaying)

	return (
		<Styles.SoundIndicator
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ delay: 0.5 }}
			darken
			onClick={() => {
				if (chromeMode === 'iosapp') {
					onIOSContextMenu()
				} else {
					mixpanel.track(AppUIEvent.ellipsesTap, {
						location: Locations.stickie,
					})
					handleClick()
				}
			}}
		>
			<Lottie
				style={{ width: '40px', height: '40px' }}
				lottieRef={lottieRef}
				autoplay={isPlaying}
				animationData={soundWhiteDots}
			/>
		</Styles.SoundIndicator>

	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.isPlaying === nextProps.isPlaying && prevProps.chromeMode === nextProps.chromeMode)

export default React.memo(SoundIndicator, areEqual)
