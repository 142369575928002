
import styled from 'styled-components'
import Link from 'gatsby-link'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

export const CloseContainer = styled(motion.div)`
	height: 40px;
	width: 40px;
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 0px;
`

export const CloseLink = styled(Link)`
	cursor: pointer;
	/* position: absolute;
	top: 8px;
	right: 8px;*/
	padding: 0px; 


	display: flex;
	
	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;
	
	-webkit-justify-content: center;
	justify-content: center;

	height: 40px;
	width: 40px;
	box-sizing: border-box;
	border: 0px;
	border-radius:50%;
	background-color:var(--gray1At40Percent);
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	@media ${device.isTabletOrLarger} { 
		right:${desktopDemoDevice.borderWidth}px;
	}
`
