import styled, { css } from 'styled-components'
import { motion, Variants } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

export interface ShareDialogProps {
  readonly isActive: boolean
}

interface test {
  isPreview:boolean
}

export const variants:Variants = {
	visible: {
		transition: {
			delay: 1.5,
			duration: 1.5,
			repeat: Infinity,
			repeatDelay: 5,
		},
		backgroundColor: ['var(--brandColor)', 'var(--brandColor)', 'var(--brandColor)', 'var(--brandColor)', 'var(--brandColor)'],
		opacity: [0.1, 0.3, 0.3, 0.3, 0.1],
		scale: [1, 1.2, 1.2, 1, 1],
		rotate: [0, 0, 270, 270, 0],
		borderRadius: ['20%', '20%', '50%', '50%', '20%'],
	},
	hidden: {
		opacity: 0,
		background: 'transparent',
	},
}

export const variantsForCustomFieldBox: Variants = {
	visible: {
		display: 'block',
		backgroundImage: 'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0))',
		transition: {
			duration: 0.5,
			y: { stiffness: 1 },
		},
		translateY: 0,

	},
	hidden: {
		display: 'none',
		backgroundImage: 'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0))',
		transition: {
			duration: 0.25,
			y: { stiffness: 1000 },
		},
		translateY: -100,
	},
	preview: {
		display: 'block',
		backgroundColor: '#FFFFFF00',
		backgroundImage: 'linear-gradient(rgba(0,0,0,1),rgba(115,115,115,0))',
		translateY: 0,
		// shadow: '0px 0px 0px #FFFFFF00',
		// style: { boxShadow: '0px 0px 0px #FFFFFF00' },
	},

}

export const variantsForCustomMessages: Variants = {
	visible: {
		display: 'block',
		transition: {
			duration: 0.5,
			y: { stiffness: 1 },
		},
		translateY: 0,
		opacity: 1,
	},
	hidden: {
		display: 'none',
		// display: 'block',
		transition: {
			duration: 0.4,
			y: { stiffness: 1000 },
		},
		translateY: -300,
		opacity: 0,
	},
	preview: {
		display: 'block',
		backgroundColor: '#FFFFFF00',
		translateY: 0,
		opacity: 1,
	},

}

export const InputContainer = styled.div`
	width: 190px;
	/* border:1px solid red; */
	height: 56px;
	display:flex;
	justify-content: center;
	align-items: center;
`
export const NameBox = styled.input`
	/* position: absolute;
	left: 60px;
	top: 8px; */
	width: 190px;
	max-height: 40px;
	height: 40px;
	padding: 0px;
	margin: 0px;
	padding-left:16px;
	padding-right:16px;
	text-align: left;
	box-sizing: border-box;
	border: none;
	font-family: 'DM Sans';
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--gray1);
	&:focus {
			outline: none;
			box-shadow: none;
			border: none;
	}
	${(props) => props.disabled && css`
     /* opacity 0.0; */
		 display:none;
  `}
	background: var(--background);
	border-radius: 8px;
`

export const StyledShareOverlay = styled.div<ShareDialogProps>`
	display: ${(props) => (props.isActive ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: 1000000;
	background-color: rgba(0,0,0,.75);
	@media ${device.isTabletOrLarger} { 
		top: calc((100vh / 2) -  (${desktopDemoDevice.height + (desktopDemoDevice.borderWidth * 2)}/2)px);
		left: calc((100vw / 2) -  (${desktopDemoDevice.width + (desktopDemoDevice.borderWidth * 2)}/2)px);
		width: ${desktopDemoDevice.width + desktopDemoDevice.borderWidth * 2}px;
		height: ${desktopDemoDevice.height + desktopDemoDevice.borderWidth * 2}px;
		border-radius: ${desktopDemoDevice.borderRadius - desktopDemoDevice.borderWidth}px;
	} 

`
export const StyledShareDialog = styled.div`
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100vw;
	height: calc(50vh * 0.9)px;
	box-sizing: border-box;
  border: 1px solid #ddd;
  padding-top: 20px;
  border-radius: 8px 8px ${desktopDemoDevice.borderRadius - desktopDemoDevice.borderWidth}px ${desktopDemoDevice.borderRadius - desktopDemoDevice.borderWidth}px;
  background-color: #fff;

	@media ${device.isTabletOrLarger} { 
		/* bottom: ${desktopDemoDevice.borderWidth}px; */
		/* left: ${desktopDemoDevice.borderWidth}px; */
		
		width: ${desktopDemoDevice.width}px;
		height: ${(desktopDemoDevice.height / 2) * 0.9}px;
	} 
`

export const PreviewLabel = styled.div`
	/* border: 1px solid red; */
	background-color: rgba(0, 0, 0, 0.6);
	color:var(--white1);
	font-size:14px;
	font-weight:400;
	border-radius: 16px;
	width: 82px;
	height: 32px;
	/* text-align: center; */
	display:flex;
	justify-content: center;
	align-items: center;
`

export const TextButton = styled.button`
	cursor: pointer;
	font-size: 16px;
	margin: 0px 16px 0px 16px;
	padding: 0px;
	height: 40px;
	width: auto;
  color: var(--gray1);
  font-family: 'DM Sans';
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 19px;
  /* border: 1px solid red; */
	background-color: transparent;
	${(props) => props.disabled && css`
     opacity 0.1;
  `}
	-webkit-tap-highlight-color: rgba(0,0,0,0);
`

export const EditButton = styled(TextButton)<{darken:boolean}>`
	color: ${(props) => (props.darken ? 'var(--gray1)' : 'var(--white1)')};
`

export const SendButton = styled(TextButton)<{darken:boolean}>`
	color: ${(props) => (props.darken ? 'var(--gray1)' : 'var(--white1)')};
`

export const CustomFieldContainer = styled(motion.div).attrs(() => ({
	initial: 'hidden',
	variants: variantsForCustomFieldBox,
}))<test>`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 56px;
	/* border: 1px solid red; */
	background-color: #fff;
	
	
	display: flex !important;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
	z-index: 1000001;

	 @media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		border-top-left-radius: ${desktopDemoDevice.borderRadius - desktopDemoDevice.borderWidth}px;
		border-top-right-radius: ${desktopDemoDevice.borderRadius - desktopDemoDevice.borderWidth}px;
	} 

	${(props) => props.isPreview && css`
     box-shadow:none;
  `}
`

export const CustomMessagesContainer = styled(CustomFieldContainer)`
	/* height: 165px; */
`

export const MessageBox = styled.textarea`
	box-sizing: border-box;
	resize: none;
	word-wrap: break-word;
	word-break: break-all;
	overflow: hidden;
	width: 250px;
	height: 75px;
	max-height: 75px;
	margin: 0px;
	padding: 5px;
	text-align: center;
	border: 1px solid var(--gray5At35Percent);
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: var(--gray1);
	&:focus {
			outline: none;
			border: 1px solid var(--brandColor70Percent);
	}
	background: var(--white1);
	border-radius: 4px;
	::-webkit-scrollbar {
		display: none;
	}
`
