import React from 'react'
import sha1 from 'sha1'
import * as Styles from './styles'
import { SlideshowContext, SlideshowContextProps } from '../SlideshowProvider'
import { SlideshowTypeKeyed } from '../../../common/shared/npc-gen-1/schema'
// import { MixpanelContext, AppUIEvent, Locations } from '../../../common/tracking'
// import { siteData } from '../../../common/siteData'

interface ComponentProps {
	onBidHandler:Function
	itemValue: number
}

// const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.stickieId === nextProps.stickieId)

const StoredValue:React.FC<ComponentProps> = () => {
	const { slideshow }:Partial<SlideshowContextProps> = React.useContext(SlideshowContext)
	const { ogTitle, stats } = slideshow as SlideshowTypeKeyed
	// const { onBidHandler, itemValue } = props
	// const mixpanel = useContext(MixpanelContext)

	return (
		<Styles.Container>
			{/* {ogTitle} */}
			<Styles.HeaderImageContainer>
				<Styles.HeaderImage src={slideshow?.defaultImage} alt="" />
			</Styles.HeaderImageContainer>
			<Styles.StatContainer>
				<Styles.StatItem>
					{/* <Styles.StatValue>{`$${itemValue.toFixed(2)}`}</Styles.StatValue> */}
					<Styles.StatValue>{ogTitle}</Styles.StatValue>
					{/* <Styles.StatLabel>Value</Styles.StatLabel> */}
				</Styles.StatItem>
			</Styles.StatContainer>
			{/* <Styles.BidContainer> */}
			{/* <Styles.Description>

				</Styles.Description> */}
			<Styles.TraitsContainer>
				{
					stats.map((item) => (
						<Styles.TraitContainer key={`trait-${sha1(item.name)}`}>
							<Styles.TraitLabel>{item.name}</Styles.TraitLabel>
							<Styles.TraitValue>{item.value}</Styles.TraitValue>
							<Styles.TraitRarity>{`${item.rarity * 100}% have this stat`}</Styles.TraitRarity>
						</Styles.TraitContainer>
					))
				}

			</Styles.TraitsContainer>
			{/* <Styles.BidButton
					onClick={() => {
						mixpanel.track(AppUIEvent.navigationBid, {
							location: Locations.stickieDrawer,
							cta: siteData.bidButtonText,
						})
						onBidHandler()
					}}
				>
					{siteData.bidButtonText}
				</Styles.BidButton> */}
			{/* </Styles.BidContainer> */}
		</Styles.Container>
	)
}

export default StoredValue
