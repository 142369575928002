import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState } from 'react'

export interface HookOutput {
  itemValue: number
}

const useStickieValue = (stickerId: string): HookOutput => {
	const [itemValue, setItemValue] = useState<number>(0)

	useEffect(() => {
		const ref = firebase.firestore().collection('stickerStats').doc(stickerId)
		const unsubscribe = ref.onSnapshot((doc) => {
			// console.log(`doc=${JSON.stringify(doc.data())}`)
			const { itemValue: newItemValue } = doc.data() || { itemValue: 0 }
			setItemValue(newItemValue)
		})
		return (() => {
			unsubscribe()
		})
	}, [stickerId])

	return {
		itemValue,
	}
}

export default useStickieValue
