import {
	useState,
	useContext,
} from 'react'
import usePrevious from './usePrevious'
import { MixpanelContext, AppStateEvent } from '../common/tracking'

export type StageType = 'sign up' | 'inactive'

interface HookOutput {
	stage: StageType
	previousStage: StageType
	setStage: any
	hasSeenModal: boolean
	setHasSeenModal: any
}

const useBidUIManager = ():HookOutput => {
	const [stage, _setStage] = useState<StageType>('inactive')
	const [hasSeenModal, setHasSeenModal] = useState<boolean>(false)

	const previousStage = usePrevious(stage)
	const mixpanel = useContext(MixpanelContext)

	const setStage = (val:StageType):void => {
		if (val === 'sign up') {
			mixpanel.track(AppStateEvent.bidSignUp)
		}

		_setStage(val)
	}

	return {
		stage,
		previousStage,
		setStage,
		hasSeenModal,
		setHasSeenModal,
	}
}

export default useBidUIManager
