import React, { useContext } from 'react'
import numeral from 'numeral'
import firebase from 'gatsby-plugin-firebase'
import { IScoreboard, SlideshowTypeKeyed, ILeader } from '../../../common/shared/npc-gen-1/schema'
import * as Styles from './styles'
import { AuthContext, AuthContextProps } from '../../User/AuthProvider'
import SplitText from './SplitTextScore'
import Arrow1 from '../../../images/svg/arrow1.svg'
import Leaderboard from '../Leaderboard'
import { SlideshowContext, SlideshowContextProps } from '../SlideshowProvider'
import CornerIcon from '../../../images/svg/corner-grab.svg'
import Points from './Points'
import usePrevious from '../../../hooks/usePrevious'
import { MixpanelContext } from '../../../common/tracking'

interface IScoreData {
  rank ?: number
  score: number
  leaderboard: Array<ILeader>
  nickName?:string
  uid?: string
}

type ScoreViewMode = 'scoreboard' | 'leaderboard' | 'hidden'
const useScoreboard = (stickerId:string):{scoreInfo:IScoreData; diff:number} => {
	const { user }:Partial<AuthContextProps> = React.useContext(AuthContext)
	const { uid } = { ...user }
	const [scoreInfo, setScoreInfo] = React.useState<IScoreData>({
		score: -1,
		leaderboard: [] as Array<ILeader>,
	})

	const previousScore = usePrevious(scoreInfo.score || 0)
	const [lastScoreDiff, setLastScoreDiff] = React.useState<number>(0)

	React.useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection('stickerStats')
			.doc(stickerId)
			.collection('users')
			.where('uid', '==', uid || null)
			.onSnapshot((snapshot:any) => {
				// console.log('snapshot query returned')
				// You can "listen" to a document with the onSnapshot() method.
				if (snapshot.empty) {
					setScoreInfo({
						score: 0,
						leaderboard: [] as Array<ILeader>,
					} as IScoreData)
				} else {
					const doc = snapshot.docs[0].data()
					// console.log(`doc: ${JSON.stringify(doc)}`)
					const newScoreInfo:IScoreData = {
						score: doc.score || 0,
						rank: doc.rank,
						nickName: doc.nickName,
						leaderboard: doc.leaderboard || [] as Array<ILeader>,
						uid,
					}

					setScoreInfo(newScoreInfo)
				}
			})
		return () => unsubscribe()
	}, [stickerId, uid])

	React.useEffect(() => {
		if (previousScore !== -1 && scoreInfo.score > previousScore) {
			const diff = scoreInfo.score - previousScore
			// console.log(`scoreInfo.score = ${scoreInfo.score}`)
			// console.log(`previousScore = ${previousScore}`)
			// console.log(`diff = ${diff}`)
			setLastScoreDiff(diff)
		}
	}, [scoreInfo.score, previousScore])

	return { scoreInfo, diff: lastScoreDiff }
}

const variants = {
	scoreboard: {
		bottom: '320px',
	},
	leaderboard: {
		bottom: '0px',
	},
	hidden: {
		bottom: '400px',
	},
}

const getScoreViewAnimation = (score:number, viewMode:ScoreViewMode): string => {
	if (score === -1) {
		return 'hidden'
	}

	if (viewMode === 'scoreboard') {
		return 'scoreboard'
	}

	return 'leaderboard'
}

type ComponentProps = {
	setOpen: Function
}

const View = (props:ComponentProps):JSX.Element => {
	const { setOpen } = props
	const { slideshow }:Partial<SlideshowContextProps> = React.useContext(SlideshowContext)
	const { id: stickerId, scoreboard, name } = slideshow as SlideshowTypeKeyed
	const { scoreFormat, label } = scoreboard as IScoreboard
	const { scoreInfo, diff } = useScoreboard(stickerId)
	const {
		score, leaderboard,
	} = scoreInfo

	// const { score } = scoreData
	const [scoreViewMode, setScoreViewMode] = React.useState<ScoreViewMode>('scoreboard')
	// console.log(`scoreInfo=${JSON.stringify(scoreInfo)}`)
	// console.log(`getScoreViewAnimation=${getScoreViewAnimation(score, scoreViewMode)}`)
	// console.log(`score === undefined ${score === undefined}`)
	const mixpanel = useContext(MixpanelContext)

	React.useEffect(() => {
		if (scoreViewMode !== 'leaderboard') return

		// console.log('refreshRank')
		const refreshRank = firebase.functions().httpsCallable('refreshRank')
		refreshRank({ stickerId })
		// .then((result) => {
		// // Read result of the Cloud Function.
		//   // console.log(result)
		// })
		// .catch((error) => {
		// // Getting the Error details.
		//   console.error(error)
		// // var code = error.code;
		// // var message = error.message;
		// // var details = error.details;
		// // // ...
		// })
	}, [scoreViewMode, stickerId])

	React.useEffect(() => {
		setOpen(scoreViewMode === 'leaderboard')
	}, [scoreViewMode, setOpen])

	const mode:string = getScoreViewAnimation(score, scoreViewMode)
	return (
		<>
			<Styles.Overlay
				isActive={mode === 'leaderboard'}
				onClick={() => {
					mixpanel.track(`Navigation: ${scoreViewMode === 'leaderboard' ? 'Scoreboard' : 'Leaderboard'}`, {
						location: 'Stickie: Overlay',
					})
					setScoreViewMode(scoreViewMode === 'leaderboard' ? 'scoreboard' : 'leaderboard')
				}}
			/>
			<Styles.Container
				initial="hidden"
				animate={mode}
				// animate="scoreboard"
				variants={variants}
				// exit={{ opacity: 0 }}
			>
				<Styles.LeaderboardContainer>
					{scoreInfo?.score <= 0 && (<Styles.StartRanking>{`Interact with ${name} to start ranking!`}</Styles.StartRanking>) }
					{scoreInfo?.score >= 1
					&& (
						<>
							{
								leaderboard.length > 0 ? (
									<Leaderboard leaders={leaderboard} />
								) : (<Styles.StartRanking>Loading...</Styles.StartRanking>)
							}
						</>
					)}
				</Styles.LeaderboardContainer>
				<Styles.BottomTab onClick={() => {
					mixpanel.track(`Navigation: ${scoreViewMode === 'leaderboard' ? 'Scoreboard' : 'Leaderboard'}`, {
						location: 'Stickie: Scoreboard Tab',
					})
					setScoreViewMode(scoreViewMode === 'leaderboard' ? 'scoreboard' : 'leaderboard')
				}}
				>

					<Styles.BottomTabInnerContainer style={{ opacity: scoreViewMode === 'leaderboard' ? 1 : 0 }}>
						<Styles.ButtonContainer>
							<Arrow1 width={16} height={16} />
						</Styles.ButtonContainer>
					</Styles.BottomTabInnerContainer>

					<Styles.BottomTabInnerContainer key={`score-inner-container-${score}`} style={{ opacity: scoreViewMode === 'scoreboard' ? 1 : 0 }}>
						<Styles.ScoreLabel>
							{`${label?.toUpperCase()} `}
						</Styles.ScoreLabel>
						<Styles.ScoreValue
							key={`score-value-${score}`}
						>
							<SplitText
								// key={`score-split-text-${score}`}
								initial={{ y: '100%' }}
								animate="visible"
								variants={{
									visible: (i:number) => ({
										y: 0,
										transition: {
											delay: i * 0.1,
										},
									}),
								}}
							>
								{`${numeral(score).format(scoreFormat)}`}
							</SplitText>
						</Styles.ScoreValue>
						<Points diff={diff} />
					</Styles.BottomTabInnerContainer>
					<Styles.CornerGrabber style={{ opacity: scoreViewMode === 'scoreboard' ? 1 : 0 }}>
						<CornerIcon />
					</Styles.CornerGrabber>
				</Styles.BottomTab>
			</Styles.Container>
		</>
	)
}

export default View
