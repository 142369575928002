import React from 'react'
import uniqid from 'uniqid'
import * as Styles from './styles'
import { IEnvironmentStateDefinition } from '../../../common/shared/npc-gen-1/sm-schema'
import { ITickEnvironmentState } from '../hooks/useEnvironmentState'

interface ComponentProps {
	environmentState: ITickEnvironmentState
}

const TimeOfDay:React.FC<ComponentProps> = ({ environmentState }) => {
	const { state: newEnvironmentState } = environmentState

	if (newEnvironmentState) {
		const { minutesTillStageOfDayChange, nextStageOfDayDescription } = newEnvironmentState as IEnvironmentStateDefinition
		if (minutesTillStageOfDayChange && nextStageOfDayDescription) {
			return (
				<Styles.Container
					key={uniqid()}
					initial={{ opacity: 0 }}
					animate={{ opacity: [0, 1, 0] }}
					transition={{ duration: 10 }}
				>
					{/* {`It's ${currentStageOfDayDescription}, ${minutesTillStageOfDayChange} min${minutesTillStageOfDayChange === 1 ? '' : 's'} till ${nextStageOfDayDescription}`} */}
					<Styles.Message>
						{`${nextStageOfDayDescription} in ${minutesTillStageOfDayChange} min${minutesTillStageOfDayChange === 1 ? '' : 's'}`}
					</Styles.Message>
				</Styles.Container>
			)
		}
	}
	return <></>
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.environmentState === nextProps.environmentState)
export default React.memo(TimeOfDay, areEqual)
