import styled from 'styled-components'
import { motion } from 'framer-motion'
// import { device, desktopDemoDevice } from '../../../utils/device'
import { LargeButton } from '../../../styles/CoreStyles'

export const Container = styled(motion.div)`
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	/* border: 1px solid green; */
`

export const HeaderImageContainer = styled.div`
	height: 88px;
	width: 88px;
	/* border-radius: 50%; */
	/* border: 1px solid var(--gray6); */
`

export const HeaderImage = styled.img`
	background-color: transparent;
  width: 100%;
	height: 100%;
	object-fit: contain;
`

export const StatContainer = styled.div`
	margin: 10px 0 0 0;
	height: 66px;
	width: 100%;
	/* border: 1px solid green; */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

export const StatItem = styled.div`
	height: 42px;
	width: 100px;
	
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`

export const StatValue = styled.div`
	height: 24px;
	width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: var(--gray1);
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
`

export const StatLabel = styled.div`
	height: 16px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	color: var(--gray3);
	font-weight: 500;
	line-height: 15px;
	text-transform: uppercase;
`

export const BidContainer = styled.div`
	margin: 0px 16px 0 16px;
	padding: 5px 0 5px 0;
	/* height: 100px; */
	/* width: 100%; */
	border-top: 1px solid var(--gray6);
	border-bottom: 1px solid var(--gray6);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
`

export const Description = styled.div`
	line-height: 18px;
	/* width: 100%; */
	margin: 14px 27px 10px 27px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: var(--gray3);
	font-weight: 400;
	box-sizing: border-box;
`

export const BidButton = styled(LargeButton)`
	width: 259px;
	margin-bottom: 10px;
`

export const TraitsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	row-gap: 10px;
	column-gap: 12px;
	width: 100%;
	height: 450px;
	padding: 5px 5px 5px 5px;
	box-sizing: border-box;
	/* border: 1px solid red; */
`

export const TraitContainer = styled.div`
	display: flex;
	justify-content:space-evenly;
	flex-direction: column;
	align-items: center;
	border:1px solid var(--brandColor70Percent);
	width: 110px;
	height: 70px;
	box-sizing: border-box;
	padding: 2px;
	border-radius: 8px;
	background-color: rgba(249, 0, 142, 0.02);
`

export const TraitLabel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* border:1px solid green; */
	width: 100%;
	height: 14px;
	box-sizing: border-box;
	/* margin: 2px; */
	font-size: 11px;
	color: var(--brandColor);
	font-weight: 400;
	box-sizing: border-box;
	text-transform: uppercase;
`
export const TraitValue = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* border:1px solid green; */
	width: 100%;
	height: 14px;
	box-sizing: border-box;
	/* margin: 2px; */
	font-size: 11px;
	color: var(--gray3);
	font-weight: 600;
	box-sizing: border-box;
	
`

export const TraitRarity = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* border:1px solid green; */
	width: 100%;
	height: 14px;
	box-sizing: border-box;
	/* margin: 2px; */
	font-size: 10px;
	color: var(--gray3);
	font-weight: 400;
	box-sizing: border-box;
	
`
