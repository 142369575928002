import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'
import { EffectPositioningContainerProps, EffectResizingContainerProps } from '../Effects/common'

export const EffectPositioningContainer = styled(motion.div)<EffectPositioningContainerProps>`
	/* border: 1px solid red; */
	position: absolute;
	bottom: 0px;
	right: 0px;
	/* top: ${(props) => props.top}; */
	/* left: ${(props) => props.left}; */
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 100vw;
	transform: 
		translateX(calc((${(props) => parseFloat(props.left)}*100vw)/100))
		translateY(calc((${(props) => parseFloat(props.top)}*100vw)/100))
		;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.width}px;
		transform:
			translateX(calc((${(props) => parseFloat(props.left)}*${desktopDemoDevice.width}px)/100))
			translateY(calc((${(props) => parseFloat(props.top)}*${desktopDemoDevice.width}px)/100))
			;
	}
	z-index: ${(props) => props.zIndex};
	opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`

export const EffectResizingContainer = styled.div<EffectResizingContainerProps>`
	/* border: 1px dotted green; */
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	transform: ${(props) => `rotate(${props.rotation}rad) scale(${props.scale}) ${props.scaleX ? `scaleX(${props.scaleX})` : ''}`};
`

export const EffectImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`
