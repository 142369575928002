import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import { device, desktopDemoDevice } from '../../../utils/device'
// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/

export const StyledMenu = styled.nav<{open:boolean}>`
	/* z-index: calc(1000000 + 11); */
	display: flex;
  flex-direction: column;
	justify-content:flex-start;
	background-color: var(--white1);
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  text-align: center;
	
	
	position: absolute;
  top: 0;
  left: 0;
	z-index: 11;
	/* position: fixed; */
	/* top: 0px;
	left: 50%; */
	/* transform: translateX(-50%); */
	
	
	
	
	
	transition: transform 0.3s ease-in-out;
	/* height: calc(100vh - 200px); */
	min-height: 100vh;
  /* mobile viewport bug fix */
  /* min-height: -webkit-fill-available; */
	width: 83vw;
	@media ${device.isTabletOrLarger} { 
		height: calc(${desktopDemoDevice.height}px);
		min-height: calc(${desktopDemoDevice.height}px);
		width: calc(${desktopDemoDevice.width * 0.83}px);
	}
	/* border: 1px solid red; */
`

export const StyledAnchor = styled.a`
	font-size: 16px;
	line-height:56px;
	font-weight: 400;
	padding: 10px 0 10px 0;
	color: var(--gray1);
	&:hover {
	color: var(--gray1);
	}
	text-align: left;
	text-decoration: none;
	display: flex;
	flex-direction: row;

	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;

	-webkit-justify-content: center;
	justify-content:start;

	width: 100%;
	border-bottom: .25px solid var(--gray6);
`

export const StyledLink = styled(Link)`
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	line-height:56px;
	font-size: 16px;
	font-weight: 400;
	padding: 10px 0 10px 0;
	color: var(--gray1);
	&:hover {
		color: var(--gray1);
	}
	text-align: left;
	text-decoration: none;

	display: flex;
	flex-direction: row;

	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;
	
	-webkit-justify-content: center;
	justify-content:start;

	width: 100%;
	border-bottom: .25px solid var(--gray6);
`

export const StyledButton = styled.button<{disabled:boolean}>`

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	line-height:56px;
	font-size: 16px;
	font-weight: 400;
	padding: 10px 0 10px 0;
	color: var(--gray1);
	&:hover {
		color: var(--gray1);
	}
	text-align: left;
	text-decoration: none;
	
	display: flex;
	flex-direction: row;

	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;
	
	-webkit-justify-content: center;
	justify-content:start;

	width: 100%;
	border-bottom: .25px solid var(--gray6);
	background-color: white;
	${(props) => props.disabled && css`
     opacity 0.2;
  `}
`

export const MenuContainer = styled.div`
	z-index: calc(1000000 + 11);
	/* position: absolute; */
	position: fixed;
  top: 0px;
	left: 0px;
	@media ${device.isTabletOrLarger} { 
		/* Safari  */
		position: absolute; 
	}
`

export const QRCodeContainer = styled.div`
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
	width: 100%;
	height: 300px;
	/* border: 1px solid var(--brandColor); */
	/* box-shadow: 0 0 1pt 1pt var(--brandColor20Percent); */
	background: var(--background);
	/* border-radius: 4px; */
`

export const SocialContainer = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
  justify-content: center;
	width: 100%;
	height: 100px;
	border: 1px solid red;
	
`

export const LinkSection = styled.div`
	margin-top:10px;
	/* height: 230px; */
	width: 100%;
	padding: 0 0 0 10px;
	box-sizing:border-box;
	border-top: .25px solid var(--gray6);
	
`

export const QRCodeMessage = styled.div`
	/* width:200px; */
	font-size: 16px;
	color: var(--gray1);
	line-height:52px;
	font-weight: 400;
`
/*
export const StyledCloseButton = styled.button`
	cursor: pointer;
	position: absolute;
	left: 8px;
	top: 8px;
	height: 40px;
	width: 40px;
	box-sizing: border-box;
  border: 0px;
	background-color: transparent;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	&:focus {
		outline: none;
	}
 	@media ${device.isTabletOrLarger} {
		left:10px;
	}
`
*/

export const AuthContainer = styled.div`
	position: relative;
	margin-top:0px;
	height: 56px;
	width:100%;
	/* border: 1px solid green; */
	display:flex;
	flex-direction: column;
	justify-content: center;
`

export const Heading = styled.div`
	font-size: 16px;
	height: 21px;
	width:180px;
	color: var(--gray1);
	background-color: transparent;
  font-weight: 700;
  line-height: 21px;
	text-align: center;
	box-sizing:border-box;
	/* border:1px solid red; */
	white-space: nowrap;
  overflow: hidden;
	text-overflow:ellipsis;
`

export const SubHeading = styled.div`
	font-size: 11px;
	height: 15px;
	width:100px;
	color: var(--gray3);
	background-color: transparent;
  font-weight: 500;
  line-height: 15px;
	text-align: center;
	box-sizing:border-box;
`

export const QRCodeCTAButton = styled.button`
	font-size: 16px;
	margin: 10px 0 0 0;
	padding: 0px;
	height: 21px;
	width:200px;
	color: var(--brandColor);
	background-color: transparent;
  font-weight: 700;
  line-height: 21px;
	text-align: center;
	box-sizing:border-box;
	/* border:1px solid red; */
`

export const QRCodeCTAButtonIcon = styled.span`
	float: right;
	width: 21px;
	height: 21px;
	margin-top:1px;
	/* border: 1px solid green; */
`

export const LogoContainer = styled.div`
	position:absolute;
	bottom: 0px;
	text-align: center;
	width: 100%;
`

export const Icon = styled.div`
	width: 48px;
	height: 48px;

	display: flex;
	
	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;
	
	-webkit-justify-content: center;
	justify-content: center;
`

export const Text = styled.div`
 	width: 150px;
	height: 48px;
	color: var(--gray1);
	font-size:16px;

	display: flex;
	
	-webkit-align-items: center; /* Safari 7.0+ */
	align-items: center;
`
