
import React from 'react'
import { SlideshowTypeKeyed } from '../../common/shared/schema12'
import { AuthProvider } from '../User/AuthProvider'
import { SlideshowProvider } from './SlideshowProvider'

// const Wrapper = ({ slideshow, children }:{slideshow: SlideshowTypeKeyed | undefined;children:any}):JSX.Element => {
const Wrapper = (props:any):JSX.Element => {
	const { slideshow, children }:{slideshow: SlideshowTypeKeyed | undefined;children:any} = props

	return (
		<SlideshowProvider slideshow={slideshow}>
			<AuthProvider enableAnonymouseAccountCreation>
				{children}
			</AuthProvider>
		</SlideshowProvider>
	)
}

export default Wrapper
