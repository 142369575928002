import styled from 'styled-components'
import { device, desktopDemoDevice } from '../../../utils/device'

export const Modal = styled.div`
	position: fixed;
	width: 327px;
	height: 298px;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 1;
	background: #FFFFFF;
	border-radius: 12px;
	box-sizing: border-box;
	padding: 36px 24px 24px 24px;
	color: var(--gray1);
	text-align: center;
	@media ${device.isTabletOrLarger} { 
		top: calc((100vh / 2) -  (${desktopDemoDevice.height + (desktopDemoDevice.borderWidth * 2)} / 2) + 50);
	}   
`

export const Title = styled.div`
	position:relative;
	font-weight: 700;
	line-height: 27px;
	font-size: 21px;
	display: block;
	width: 100%;
	margin-bottom:20px;
`

export const SubTitle = styled.div`
	font-weight: 400;
  line-height: 21px;
	font-size: 16px;
	position: relative;
	display: block;
	width: 100%;
	padding-left:20px;
	padding-right:20px;
	margin: 0px 0px 26px 0px;
	box-sizing: border-box;
	color: var(--gray3);
`

export const OkButton = styled.button`
	font-weight: 700;
	line-height: 21px;
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	height: 48px;
	width: 127px;
	color: var(--white1);
	border-radius: 8px;
	background-color: var(--brandColor);
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 19px;
`

export const NoThanksButton = styled.button`
	font-size: 16px;
	margin: 0px;
	margin-top: 25px;
	padding: 0px;
	height: 48px;
	width: 200px;
	color: var(--gray3);
	background-color: transparent;
  font-weight: 500;
  line-height: 21px;
	text-align: center;
	box-sizing:border-box;
`
