import React from 'react'
import { sample, findIndex } from 'lodash'
import * as Styles from './styles'
import {
	EffectTypeKeyed, SlideTypeKeyed,
} from '../../../common/shared/schema12'
import { device, desktopDemoDevice } from '../../../utils/device'

interface ComponentProps {
	effects: Array<EffectTypeKeyed>
	currentSlide: SlideTypeKeyed
	emptyImageUrlsForGifHack: boolean
}

const getAnimation = (effect:EffectTypeKeyed):{initial:{};animate:{};transition:{}} => {
	const {
		animation, left, top, opacity,
	} = effect
	let initial = {}
	let animate = {}
	let transition = {}
	const animationType = animation?.type || ''
	const delay = animation?.delay || 0

	const translateX = device.isTabletOrLarger ? ((parseFloat(left) * desktopDemoDevice.width) / 100) : `calc((${parseFloat(left)}*100vw)/100)`
	const translateY = device.isTabletOrLarger ? ((parseFloat(top) * desktopDemoDevice.width) / 100) : `calc((${parseFloat(top)}*100vw)/100))`
	// const rotateDegrees = animation?.rotation ? (animation?.rotation * 180) / Math.PI : 0

	const animationRotationRadians: number = sample(animation?.rotations) ?? animation?.rotation ?? 0
	const animationRotationDegrees = (animationRotationRadians * 180) / Math.PI

	if (animationType === 'spinIn') {
		initial = {
			opacity: opacity || 1,
			scale: 0,
			rotate: 180,
			translateX,
			translateY,
		}
		animate = {
			scale: 1,
			rotate: 0,
			translateX,
			translateY,
		}
		transition = {
			delay,
			type: 'spring',
			stiffness: 260,
			damping: 20,
		}
	} else if (animationType === 'popIn') {
		initial = {
			opacity: opacity || 1,
			scale: 0,
			rotate: 0,
			translateX,
			translateY,
		}

		animate = {
			rotate: sample([[0, -45, -10], [0, -15, 3], [0, -35, -3]]),
			scale: [0, 2.1, 1],
			translateX,
			translateY,
		}
		transition = {
			// ease: [0.17, 0.67, 0.83, 0.67],
			delay,
			duration: animation?.duration || 0.3,
			times: [0, 0.8, 1],
		}
	} else if (animationType === 'fadeIn') {
		initial = {
			opacity: 0,
			translateX,
			translateY,
		}

		animate = {
			opacity: 1,
			translateX,
			translateY,
		}
		transition = {
			delay,
			duration: animation?.duration || 0.3,
		}
	} else if (animationType === 'fadeOut') {
		initial = {
			opacity,
			translateX,
			translateY,
		}

		animate = {
			opacity: 0,
			translateX,
			translateY,
		}
		transition = {
			delay,
			duration: animation?.duration || 0.3,
		}
	} else if (animationType === 'fadeInOut') {
		initial = {
			opacity,
			translateX,
			translateY,
		}
		animate = {
			opacity: [0, 1, 0],
			translateX,
			translateY,
		}
		transition = {
			delay,
			duration: animation?.duration || 3,
			times: [0, 0.2, 1],
		}
	} else if (animationType === 'spin') {
		// console.log(`spin ${rotateDegrees}`)
		initial = {
			translateX,
			translateY,
		}
		animate = {
			rotate: animationRotationDegrees,
			translateX,
			translateY,
		}
		transition = {
			delay,
			duration: animation?.duration || 3,
			bounce: 0.6,
		}
	} else if (animationType === 'slideUp') {
		initial = {
			translateX,
			translateY: '100%',
		}
		animate = {
			translateY,
		}
		transition = {
			delay: animation?.delay || 0,
			duration: animation?.duration || 0.3,
		}
	} else if (animationType === 'slideDown') {
		initial = {
			translateX,
			translateY: '-100%',
		}
		animate = {
			translateY,
		}
		transition = {
			delay: animation?.delay || 0,
			duration: animation?.duration || 0.3,
		}
	} else if (animationType === 'slideLeft') {
		initial = {
			translateX: '100%',
			translateY,
		}
		animate = {
			translateX,
		}
		transition = {
			delay: animation?.delay || 0,
			duration: animation?.duration || 0.3,
		}
	} else if (animationType === 'slideRight') {
		initial = {
			translateX: '-100%',
			translateY,
		}
		animate = {
			translateX,
		}
		transition = {
			delay: animation?.delay || 0,
			duration: animation?.duration || 0.3,
		}
	}

	return {
		initial,
		animate,
		transition,
	}
}

// const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => true

const EffectImages = (props:ComponentProps):JSX.Element => {
	const { effects, currentSlide, emptyImageUrlsForGifHack } = props

	const effectImages = effects.filter((effectObj: EffectTypeKeyed) => effectObj.type === 'image' || effectObj.type === 'gif1x')
		.map((effectObj:EffectTypeKeyed) => {
			const {
				id: effectId, url: effectUrl, urls: effectUrls, left, top, zIndex, opacity, rotation: objRotation, rotations: objRotations,
			} = effectObj

			const objRotationRadians: number = sample(objRotations) ?? objRotation ?? 0
			const objEffectImageUrl: string = sample(effectUrls) ?? effectUrl
			// const objRotationDegrees = (objRotationRadians * 180) / Math.PI
			// console.log(`objRotationRadians=${objRotationRadians}`)
			// console.log(`effectyObj=${JSON.stringify(effectObj)}`)
			const { initial, transition, animate } = getAnimation(effectObj)
			let jsx: JSX.Element = <></>
			const showEffect = findIndex(currentSlide.effects, { id: effectId }) >= 0
			// const className = `${showEffect ? effectObj.className : 'hide-effect-at-start'}`
			// Hack to replay a single loop gif
			const transparent1PxGif = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
			const imageJSX = (
				<Styles.EffectResizingContainer
				// rotation={effectObj.rotation ? effectObj.rotation : 0}
					rotation={objRotationRadians}
					scale={effectObj.scale}
					scaleX={effectObj.scaleX}
				>
					<Styles.EffectImage
						// src={emptyImageUrlsForGifHack && effectObj.type === 'gif1x' ? transparent1PxGif : effectUrl}
						src={emptyImageUrlsForGifHack && effectObj.type === 'gif1x' ? transparent1PxGif : objEffectImageUrl}
						key={`effect-img-${effectId}`}
						alt=""
					/>
				</Styles.EffectResizingContainer>
			)

			if (showEffect) {
				jsx = (
					<Styles.EffectPositioningContainer
						key={`effect-div-${effectId}`}
						top={top}
						left={left}
						zIndex={zIndex}
						opacity={opacity}
						initial={initial}
						animate={animate}
						transition={transition}
					>
						{imageJSX}
					</Styles.EffectPositioningContainer>
				)
			} else {
				jsx = (
					<Styles.EffectPositioningContainer
						key={`effect-div-${effectId}`}
						top={top}
						left={left}
						zIndex={zIndex}
						className="hide-effect-at-start"
					>
						{imageJSX}
					</Styles.EffectPositioningContainer>
				)
			}

			return jsx
		})

	return (
		<>
			{effectImages}
		</>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.currentSlide.id === nextProps.currentSlide.id)
	&& (prevProps.emptyImageUrlsForGifHack === nextProps.emptyImageUrlsForGifHack)

const MemoizedEffects = React.memo(EffectImages, areEqual)

export default MemoizedEffects
