import React, { useContext, useRef, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import * as Styles from './styles'
import ModalOverlay from '../OverlayBackground'
import { AuthContext, AuthContextProps } from '../../User/AuthProvider'
import BackIcon from '../../../images/svg/back.svg'
import SentMail from '../../../images/svg/sent_mail.svg'
import EmailComponent, { IEmail } from '../../Email'
import { siteData } from '../../../common/siteData'
import { MixpanelContext, AppStateEvent, Locations } from '../../../common/tracking'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

type FormStages = 'idle' | 'inprocess' | 'waiting-verification' |'redo'

interface ComponentProps {
	stickieId: string
	itemName: string
	onDismiss: Function
	initialOpenState: boolean
}

const actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be in the authorized domains list in the Firebase Console.
	url: `${process.env.GATSBY_ROOT_URL}${siteData.finishBidPath}?stickieid=`,
	// This must be true.
	handleCodeInApp: true,
	// iOS: {
	// bundleId: 'com.example.ios',
	// },
	// android: {
	// packageName: 'com.example.android',
	// installApp: true,
	// minimumVersion: '12',
	// },
	dynamicLinkDomain: 'auth.stickies.fun',
}

const GetNotifiedModal = React.memo<ComponentProps>((props) => {
	const {
		onDismiss, initialOpenState = false, itemName, stickieId,
	} = props
	const { user }:Partial<AuthContextProps> = React.useContext(AuthContext)
	const [email, setEmail] = React.useState<IEmail>({ email: '', isValid: false })
	const [isOpen, setIsOpen] = React.useState<boolean>(initialOpenState)
	const [stage, setStage] = React.useState<FormStages>('idle')
	const mixpanel = useContext(MixpanelContext)

	const divRef = useRef<HTMLDivElement | null>(null)
	useOnClickOutside(divRef, () => onDismiss())

	useEffect(() => {
		mixpanel.track(AppStateEvent.getNotifiedPresented)
	}, [mixpanel])

	const handleClose = ():void => {
		mixpanel.track(AppStateEvent.getNotifiedDismissed)
		setIsOpen(!isOpen)
		onDismiss()
	}

	const handleSubmit = (theEmail:string):void => {
		setStage('inprocess')
		firebase.auth().sendSignInLinkToEmail(theEmail, {
			...actionCodeSettings,
			url: `${actionCodeSettings.url}${stickieId}`,
		})
			.then(() => {
			// The link was successfully sent. Inform the user.
			// Save the email locally so you don't need to ask the user for it again
			// if they open the link on the same device.
				window.localStorage.setItem('emailForSignIn', theEmail)

				mixpanel.track(AppStateEvent.authEmailSubmitted, {
					location: Locations.getInlineModal,
				})
			})
			.catch(() => {
				// const errorCode = error.code
				// const errorMessage = error.message
				// console.log(errorCode)
				// console.log(errorMessage)
			})
			.finally(() => {
				setStage('waiting-verification')
			})
	}

	const signUpForm = (
		<Styles.SignUpForm>

			{
				(stage === 'idle' || stage === 'redo') && user && user.isAnonymous && (
					<>
						<EmailComponent
							// initialEmail={typeof window !== 'undefined' ? window.localStorage.getItem('emailForSignIn') || '' : ''}
							defaultValue={stage === 'redo' ? email.email : ''}
							onSubmit={(data:IEmail) => {
								setEmail(data)
								handleSubmit(data.email)
							}}
							buttonText="Go"
						/>
						<Styles.NoThanksButton onClick={() => handleClose()}>No thanks</Styles.NoThanksButton>
					</>
				)
			}
			{/* {
				stage === 'inprocess' && (<div>processing...</div>)
			} */}

			{
				(stage === 'waiting-verification' || stage === 'inprocess') && (
					<>
						<SentMail />
						<Styles.InfoMessage>
							{siteData.bidLinkSentMessage}
						</Styles.InfoMessage>
					</>
				)
			}

		</Styles.SignUpForm>
	)

	const userInfo = (
		<>
			<Styles.AlreadyAuthedContainer>
				{ user && user.email && user.emailVerified && (
					<>
						<Styles.OkButton onClick={() => handleClose()}>Ok</Styles.OkButton>
						{/* <Styles.StyledLink to="/me/notifications">
							Manage Notifications
						</Styles.StyledLink> */}
						{/* <Styles.DoNotShow>

						</Styles.DoNotShow> */}
					</>
				)}
			</Styles.AlreadyAuthedContainer>
		</>
	)

	const topNav = (
		<Styles.TopNav>
			{
				(stage === 'waiting-verification' || stage === 'inprocess') && (
					<Styles.BackButton onClick={() => setStage('redo')}><BackIcon /></Styles.BackButton>
				)
			}
			{
				(stage === 'waiting-verification' || stage === 'inprocess') && (
					<Styles.DoneButton onClick={() => handleClose()}>Done</Styles.DoneButton>
				)
			}
		</Styles.TopNav>
	)

	// console.log(`email=[${user?.email}]`)
	return (
		<ModalOverlay isActive={isOpen}>
			<Styles.Modal ref={divRef}>
				{topNav}
				<Styles.Title>
					{
						(stage === 'idle' || stage === 'redo') && (
							<>Make A Bid</>
						)
					}

				</Styles.Title>

				<Styles.SubTitle>
					{
						(stage === 'idle' || stage === 'redo') && (
							<>
								{
									user && user.email && user.emailVerified ? (
										<>
											{`You're now on wait list for ${itemName}`}
										</>

									) : (
										<>
											{`Get on the wait list for ${itemName}`}
										</>
									)
								}
							</>
						)
					}
				</Styles.SubTitle>

				{signUpForm}
				{userInfo}
			</Styles.Modal>
		</ModalOverlay>
	)
})

export default GetNotifiedModal
